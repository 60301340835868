<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content">
        	<div class="block">
        		<div class="hdr"><div class="ttl">Users meta</div><div @click="createUsersMeta" class="btn create-account-btn">Create</div></div>
        		<Table class="meta-users" @editItem="editMetaUsers" @deleteItem="deleteMetaUsers" :key="usersMetaDataKey" :options="usersMetaData" @change-page="getUsersMeta" />
        		<div class="hdr providers-ttl"><div class="ttl">Providers meta</div><div @click="createProvidersMeta" class="btn create-account-btn">Create</div></div>
        		<Table class="meta-providers" @editItem="editProviderMeta" @deleteItem="deleteProviderMeta" :key="providersMetaDataKey" :options="providersMetaData" @change-page="getProvidersMeta" />
        	</div> 
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
	data () {
		return {
			usersMetaDataKey: 0,
			usersMetaData: {
		      colsWidth: ['30%', '30%', '30%','10%'],
		      header: [
		        { ttl: 'Name', info: null },
		        { ttl: 'Property', info: null },
		        { ttl: 'Value', info: null },
		        { ttl: '', info: null },
		      ],
		      body: [],
		      paginationData: null,
		    },
		    providersMetaDataKey: 0,
			providersMetaData: {
		      colsWidth: ['30%', '30%', '30%','10%'],
		      header: [
		        { ttl: 'Name', info: null },
		        { ttl: 'Property', info: null },
		        { ttl: 'Value', info: null },
		        { ttl: '', info: null },
		      ],
		      body: [],
		      paginationData: null,
		    }
		}
	},
	components: {
	    Table,
	    Pagination,
	 },
	created () {
		this.getUsersMeta()
		this.getProvidersMeta()
	},
	methods: {
		async getUsersMeta(page = 1) {
			await axios.get('admin/meta/users?page='+ page)
		        .then(({ data }) => {
		          this.usersMetaData.paginationData = data
		          this.usersMetaData.body = []
		          this.usersMetaData.paginationData.data.forEach(el => {
		            this.usersMetaData.body.push([
		              el.name, el.property, el.value, { type: 'edit-delete-actions', id: el.id}
		            ])
		          })
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
		},
		async getProvidersMeta(page = 1) {
			await axios.get('admin/meta/providers?page='+ page)
		        .then(({ data }) => {
		          this.providersMetaData.paginationData = data
		          this.providersMetaData.body = []
		          this.providersMetaData.paginationData.data.forEach(el => {
		            this.providersMetaData.body.push([
		              el.name, el.property, el.value, { type: 'edit-delete-actions', id: el.id}
		            ])
		          })
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
		},
		createUsersMeta () {
			this.$store.commit('setModal', {
		        template: 'meta',
		        options: {
		          id: null,
		          actionType: 'create',
		          table: 'meta-users',
		          objectType: 'meta users',
		          url: 'admin/meta/users',
		          successfullMessage: 'You successfully created users meta',
		        }
		      })
		},
		editMetaUsers (obj) {
			this.$store.commit('setModal', {
		        template: 'meta',
		        options: {
		          id: obj.id,
		          actionType: 'edit',
		          table: 'meta-users',
		          objectType: 'meta users',
		          url: 'admin/meta/users/' + obj.id,
		          successfullMessage: 'You successfully edited users meta',
		        }
		      }) 
		},
		deleteMetaUsers (obj) {
			this.$store.commit('setModal', {
	        template: 'delete',
	        options: {
	          id: obj.id,
	          actionType: 'delete',
	          table: 'meta-users',
	          type: 'users meta',
	          url: 'admin/meta/users/' + obj.id,
	          successfullMessage: 'You successfully deleted users meta',
	        }
	      })
		},
		createProvidersMeta () {
			this.$store.commit('setModal', {
		        template: 'meta',
		        options: {
		          id: null,
		          actionType: 'create',
		          table: 'meta-providers',
		          objectType: 'providers meta',
		          url: 'admin/meta/providers',
		          successfullMessage: 'You successfully created users meta',
		        }
		      }) 
		},
		editProviderMeta(obj) {
			this.$store.commit('setModal', {
		        template: 'meta',
		        options: {
		          id: obj.id,
		          actionType: 'edit',
		          table: 'meta-providers',
		          objectType: 'providers meta',
		          url: 'admin/meta/providers/' + obj.id,
		          successfullMessage: 'You successfully edited users meta',
		        }
		      }) 
		},
		deleteProviderMeta (obj) {
			this.$store.commit('setModal', {
		        template: 'delete',
		        options: {
		          id: obj.id,
		          actionType: 'delete',
		          table: 'meta-providers',
		          type: 'providers meta',
		          url: 'admin/meta/providers/' + obj.id,
		          successfullMessage: 'You successfully deleted users meta',
		        }
		      })
		},
	    updateCurrentPage(tableName, actionType) {
	      if (tableName == 'meta-users' || tableName == 'meta-providers') {
	          let currentPage = 1
	          if (actionType == 'edit' || actionType == 'delete') {
	          	try {
			            currentPage = document.getElementsByClassName(tableName)[0]
			            .getElementsByClassName('pagination')[0]
			            .getElementsByClassName('active')[0]
			            .getElementsByClassName('page-link')[0]
			            .innerText.trim().replace( /\D+/g, '')
			        } catch (err) {
		            console.log('Error. Please, try again.');
		          }  
	          }
	          if (tableName == 'meta-users') {
	            this.getUsersMeta(currentPage)
	          } else if (tableName == 'meta-providers') {
	            this.getProvidersMeta(currentPage)
	          }
	      } 
	    }
	},
	  watch: {
	    '$store.state.deleteItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    },
	    '$store.state.editItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    }
	  }
}
</script>

<style scoped>
.create-account-btn {
    border-radius: 1rem;
    padding: 10px;
    margin-left: 30px;
    background-color: #62CDAC;
}

.content .block .hdr {
    display: flex;
    align-items: center;
    margin-left: 0.75rem;
    margin-bottom: 0.5rem;
}

.providers-ttl {
	margin-top: 40px;
}
</style>