<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Chat Settings
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group">
          <label>Default personal manager</label>
          <model-select class="form-control" 
                :options="userEmails"
                v-model="defaultPersonalManager"
                placeholder="Enter personal manager email">
          </model-select>
          <span v-show="defaultPersonalManagerError" class="invalid-feedback">{{ defaultPersonalManagerError }}</span>
        </div>
        <div class="form-group">
          <label>Providers</label>
          <multi-select :options="providerEmails"
               :selected-options="providers"
               placeholder="Enter provider email"
               @select="onSelect"
               >
           </multi-select>
          <span v-show="providersError" class="invalid-feedback">{{ defaultPersonalManagerError }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Close from './../img/Close.vue'
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import { MultiSelect } from 'vue-search-select'

export default {
  components: {
    Close,
    ModelSelect,
    MultiSelect,
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      defaultPersonalManagerError: '',
      defaultPersonalManager: {
        value: '',
        text: ''
      },
      watchPersonalManagerEmails: false,
      userEmails: [],
      providerEmails: [],
      providers: [],
      providersError: '',
      lastSelectProvider: {},
    };
  },
  created () {
    this.getPersonalManager()
    this.getUserProviders()
    this.getUserEmails()
    this.getProviderEmails()
  },
  methods: {
    getUserProviders () {
      const that = this
      this.providers = []
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/user-providers/' + this.options.customerId)
        .then(function (response) {
          if (response.data.user_providers) {
            response.data.user_providers.forEach(provider => {
              const item = {
                value: provider.provider.id,
                text: provider.provider.email,
              }
              that.providers.push(item)
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getPersonalManager () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/user-default-manager/' + this.options.customerId)
        .then(function (response) {
          if (response.data.default_personal_manager) {
            that.defaultPersonalManager.value = response.data.default_personal_manager.personal_manager.id
            that.defaultPersonalManager.text = response.data.default_personal_manager.personal_manager.email
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getUserEmails () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/all-users-emails')
        .then(function (response) {
          that.userEmails = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getProviderEmails () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/all-providers-emails')
        .then(function (response) {
          that.providerEmails = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    resetErrors() {
      this.defaultPersonalManagerError = ''
    },
    validatePersonalManagerForm () {
      /*if (!this.defaultPersonalManager.value) {
        this.defaultPersonalManagerError = 'Please, enter personal manager email'
        return
      }
      this.resetErrors()*/
    },
    async submit() {
      this.watchPersonalManagerEmails = true
      this.validatePersonalManagerForm()
      if (this.defaultPersonalManagerError.length > 0) {
        return
      }
      this.saveChatSettings()
    },
    saveChatSettings () {
      const providerIds = this.providers.map(x => x.value)
      const that = this
          axios.post(process.env.VUE_APP_BACKEND_API_URL + 'chat/settings', {
            user_id: this.options.customerId,
            personal_manager_id: this.defaultPersonalManager.value,
            provider_ids: providerIds
          })
            .then(function (response) {
              that.watchPersonalManagerEmails = false
              that.resetErrors()
              that.$noty.success('You have successfully saved chat settings')
              that.close()
            })
            .catch(function (error) {
              console.log(error);
            })
    },
    close() {
      this.$emit("close");
    },
    onSelect (items, lastSelectItem) {
      this.providers = items
      this.lastSelectProvider = lastSelectItem
    },
    // deselect option
    reset () {
      this.providers = [] // reset
    },
    // select option from parent component
    selectFromParentComponent () {
      this.providers = _.unionWith(this.providers, [this.options[0]], _.isEqual)
    }
  },
  watch: {
    'defaultPersonalManager.value': function (userEmailValue) {
      if (this.watchPersonalManagerEmails) {
      this.validatePersonalManagerForm()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
}
</style>
