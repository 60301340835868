<template>
	<overlay-scrollbars class="page page-chat" ref="os">
		<div class="actions">
			<input v-model="searchQuery" class="search-query form-control" type="text" placeholder="Search...">
		</div>
		<div class="row main-block">
			<div class="col col-3 contacts">
				<overlay-scrollbars style="height: 100%;">
					<div @click="selectRoom(room.id)" v-for="(room, index) in rooms" :key="index">
						<div v-if="room.type == 1" class="chat" :class="{ 'room-active': room.active }">
							<div class="img">
								<img :src="room.interlocutor.logo" />
							</div>
							<div class="chat-content">
								<div class="chat-name">{{ room.interlocutor.name }}</div>
								<div class="chat-subinfo">
									<span v-if="room.unread_messages" class="puls-btn chat-subinfo-count">{{ room.unread_messages }}</span>
								</div>
							</div>
						</div>
						<div v-else class="chat" :class="{ 'room-active': room.active }">
							<div class="chat-content">
								<div class="chat-name public-chat-name" :class="{ 'room-has-name': room.hasName }">{{ room.name }}</div>
								<div class="chat-subinfo">
									<span v-if="room.unread_messages" class="puls-btn chat-subinfo-count">{{ room.unread_messages }}</span>
								</div>
							</div>
						</div>
					</div>
				</overlay-scrollbars>
			</div>
			<div class="col col-9 ">
				<div v-if="activeRoom && activeRoom.apply_offer && (activeRoom.offerable || offerProgress || offerProgressDescription)" class="apply-offer-wrapper row">
					<div class="col-6 offer-main-info">
						<div v-if="activeRoom.offerable">
							<div v-if="activeRoom.offerable_type == 'App\\Models\\Offer'">
								<div>{{ activeRoom.offerable.financial_institution_name }}</div>
								<div>{{ activeRoom.offerable.country_code }}</div>
							</div>
							<div v-else-if="activeRoom.offerable_type == 'App\\Models\\LegalService'">
								<div>{{ activeRoom.offerable.services_type }}</div>
								<div>{{ activeRoom.offerable.country ? activeRoom.offerable.country.code : '' }}</div>
							</div>
							<div v-else-if="activeRoom.offerable_type == 'App\\Models\\BusinessOffer'">
								<div>{{ activeRoom.offerable.business_type }}</div>
								<div>{{ activeRoom.offerable.country ? activeRoom.offerable.country.code : '' }}</div>
							</div>
						</div>
					</div>
					<div class="col-6">
						<div v-if="activeRoom.offer_progress || activeRoom.offer_progress_description" class="offer-progress-info">
							<div>{{ activeRoom.offer_progress }}<span v-if="activeRoom.offer_progress">%</span></div>
							<div>{{ activeRoom.offer_progress_description }}</div>
						</div>
					</div>
				</div>
				<overlay-scrollbars :style="{'height': activeRoom && activeRoom.apply_offer ? '70%' : '100%'}" ref="chatMessagesScrolls">
					<div v-if="rooms.length == 0" class="no-messages">
					</div>
					<div v-else>
						<div class="messages">
							<div v-if="activeRoom" :class="{ 'user-message' : isUserMessage(message) }" class="message" v-for="(message, index) in activeRoom.messages" :key="index">
								<div v-if="message.user_id != user.id" class="img">
									<img v-if="message.user.logo" :src="thumbsUrl +  message.user.logo" />
									<img v-else src="/img/default-avatar.png" />
								</div> 
								<div class="message-content">
									<div class="message-user-login">{{ getUserMessageLogin(message) }}</div>
									<div class="message-text pre-formatted">{{ message.message_text }}</div>
									<div class="message-text" v-for="(doc, id) in message.documents" :key="id">
										<a class="document-link" target="_blank" v-if="doc.ready || !doc.loading" :href="backendUrl + 'storage/chat/documents/' + doc.file">{{ doc.original_name }}</a>
										<div v-else>{{ doc.original_name }}</div>
										<k-progress 
										  v-if="doc.loading"
									      status="success" 
									      type="line"
									      :percent="doc.loadingPercent"
									      :color="['#05737A']"
									      :show-text="false"
									       >
									    </k-progress>
									</div>
									<div class="message-date">{{ message.created_at | moment("Y-MM-DD HH:mm") }}</div>
								</div>
							</div>
						</div>
					</div>
				</overlay-scrollbars>
			</div>
		</div>
	</overlay-scrollbars>
</template>

<script>
import Vue from 'vue'
Vue.use(require('vue-moment'))
import { mapMutations, mapActions, mapGetters } from 'vuex';
import KProgress from 'k-progress';
Vue.component('k-progress', KProgress);
import VerticalDots from '@/components/img/VerticalDots.vue'
import Pen from '@/components/img/Pen.vue'

export default {
	components: {
		VerticalDots,
		Pen,
	},
	data () {
		return {
			rooms: [], 
			activeRoom: null,
			backendUrl: process.env.VUE_APP_BACKEND_URL,
			thumbsUrl: process.env.VUE_APP_BACKEND_URL + 'storage/images/users/logos/thumbs/',
			offerShowProgressForm: false,
			offerProgress: '',
			offerProgressDescription: '',
			searchQuery: '',
			searchDelayTimer: null
		}
	},
	methods: {
	    isUserMessage (message) {
	    	return message.user_id == this.user.id
	    },
	    getUserMessageLogin (message) {
	    	if (message.user_id != this.user.id) {
		    	return message.user.company_full_name
		    }
		    return ''
	    },
		selectRoom (roomId) {
			const oldActiveRoom = this.activeRoom
			for (let i = 0; i < this.rooms.length; i++) {
				if (+this.rooms[i].id == +roomId) {	
					
					this.activeRoom = this.rooms[i]
					const el = this.rooms[i]
					el.active = true
					this.offerProgress = el.offer_progress
					this.offerProgressDescription = el.offer_progress_description
					Vue.set(this.rooms, i, el)
				} else {
					const el = this.rooms[i]
					el.active = false
					Vue.set(this.rooms, i, el)
				}
			}  
			const that = this
			setTimeout(function () {
				that.$refs.chatMessagesScrolls.osInstance().scroll({ y : "100%" }, 100)
			}, 2500)
		},
		proccessServerRoom(room) {
			if (room.type == 1) {
        		const anotherUser = room.participants[0].user.id != this.user.id ? room.participants[0] : room.participants[1]
        		room.interlocutor = {
        			name: anotherUser.user.company_full_name,
        			logo: anotherUser.user.logo ? process.env.VUE_APP_BACKEND_URL + 'storage/images/users/logos/thumbs/' +  anotherUser.user.logo : '/img/default-avatar.png'
        		}
        	} else {
        		room.name = room.name ? room.name : room.public_name
        		room.hasName = room.name ? true : false
        	}
        	room.unread_messages = room.messages.length - room.user_read_statuses_count
        	room.active = false
			return room
		},
		async getRooms() {
			const that = this
			this.rooms = []
			let url = process.env.VUE_APP_BACKEND_API_URL + 'chat/admin-rooms'
			if (this.searchQuery) {
				url = url + '?q=' + this.searchQuery
			}
			await axios.get(url).then(({data}) => {
	            for (let i = 0; i < data.length; i++) { 
	            	data[i] = this.proccessServerRoom(data[i])
	            }
	            if (data[0]) {
	            	data[0].active = true
	            	data[0].unread_messages = 0
	                that.activeRoom = data[0]
	                that.offerProgress = data[0].offer_progress
					that.offerProgressDescription = data[0].offer_progress_description
	            }
	            this.rooms = data;
	            if (this.$route.params && this.$route.params.chatId) {
					this.selectRoom(this.$route.params.chatId)
		        }
	        }) 
		},
		async getChatParticipants () {
			await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'chat/participants')
		      .then(({ data }) => {
		          this.chatParticipants = data
		      })
		      .catch((error) => console.log(error))
		},
	},
	created() {
		this.getRooms()
		this.getChatParticipants()
    },
    computed: {  
	    user () {
	      return this.$store.state.user
	    },
	},
	watch: {
	    searchQuery: function(customerName) {
	      clearTimeout(this.searchDelayTimer);
	      const that = this
	      this.searchDelayTimer = setTimeout(function() {
	      	  this.rooms = []
	          that.getRooms()
	      }, 300);
	    },
	}
}
</script>

<style type="text/css">
.no-messages {
	display: flex;
	align-items: center;
    justify-content: center;
    height: 100%;
}
.main-block {
	background: #2F363D;
	height: 79%;
	border-radius: 6px;
	margin-left: 35px;
    width: 95%;
    margin-left: 0.85rem;
} 
.contacts {
	margin: 0.5rem 0 0.5rem 0.5rem;
	border-right: 2px solid rgba(19, 180, 151, 0.1);
}
.messages {
	margin: 0.5rem;
	padding: 1rem;
}
.row {
	display: flex;
    flex-direction: row;
}
.col-3 {
	width: 30%;
}
.col-9 {
	width: 70%;
}
.send-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    right: 12px;
}

textarea.main-message {
	width: 65%;
	padding: 10px;
}

.form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    padding: 0.5rem;
    margin: auto 0rem;
    border: 1px solid #2F363D;
    border-radius: 0.5rem;
    box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    outline: none;
    -webkit-appearance: none;
}
.send-btn {
	width: 10%;
    height: 6.7rem;
    margin: 0.5rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
}

.upload-btn {
	width: 10%;
    height: 6.7rem;
    margin: 0.5rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
}

.chat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.chat .img {
	display: inline;
	margin-right: 20px;
}

.chat .img img {
	width: 50px;
	display: inline;
	border-radius: 10px;
}

.message .img {
	width: 30px;
	height: 30px;
	display: inline;
	margin-right: 20px;
}

.message .img img {
	width: 30px;
	height: 30px;
	display: inline;
	border-radius: 10px;
}
.room-active {
	background-color: #778899;
}
.message {
	margin-bottom: 15px;
}
.user-message {
	background-color: #778899;
	margin-left: 90px;
	padding: 10px;
	border-radius: 10px;
}

.send-btns {
	display: flex;
	flex-direction: column;
}

.preview-mode {
	margin: 1rem;
}

.preview-text {
	margin-left: 10px;
}

.chat-read {
	background-color: #303030;
}

.chat-content {
	display: flex;
	flex-direction: row;
}
.chat-content {
	display: flex;
	flex-direction: column;
}

.chat-name {
	margin-bottom: 5px;
}

.chat-subinfo {
	margin-top: 5px;
	font-size: 10px;
}

.chat-subinfo-count {
	border-radius: 20px;
	background-color: #1C2126;
	padding: 0.5rem;
}

.public-chat-name {
	font-size: 10px;
}

.room-has-name {
	font-size: 14px;
}

.message-content {
	display: flex;
	flex-direction: column;
}

.message-date {
	font-size: 8px;
}

.message-user-login {
	margin-bottom: 5px;
}

.message-text {
	margin-bottom: 5px;
}

.pre-formatted {
  white-space: pre;
}

.files-input {
	display: none;
}

.document-link {
	color: #ffffff;
}

.add-chat-btn {
	width: 10%;
    height: 2rem;
    margin: 0.75rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
}

.puls-btn {
  animation: btnPulse-2 2.5s infinite alternate ease-in-out;
}

@keyframes btnPulse-2 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
    background: #21b184;
  }
  50% {
    transform: scale(1);
    background: #8ad4bd;
  }
}

.menu-settings-img {
	width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 1.1rem;
}

.actions {
	display: flex;
	flex-direction: row;
}

.apply-offer-wrapper {
	padding: 1rem;
	background-color: #535F6B;
}

.col-6 {
	width: 50%;
}

.offer-desc-input {
	margin: 0.5rem 0;
}

.save-offer-desc-btn {
	width: 4rem;
    height: 2rem;
    margin: 0.5rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
113.24deg
, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
}

.offer-progress-info {
	position: relative;
}

.edit-offer-desc-btn {
	position: absolute;
	right: 0px;
	top: -10px;
	width: 4rem;
    height: 2rem;
    margin: 0.5rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
113.24deg
, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
}

.select-offer-desc-btn {
	width: 12rem;
    height: 2rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
113.24deg
, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
}

.cancel-offer-desc-btn {
	width: 8rem;
    height: 2rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: linear-gradient(
113.24deg
, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
}

.edit-offer-desc-btn svg.img {
    width: 1rem;
    height: 1rem;
}

.select-offer-action-btns {
	display: flex;
	flex-direction: row;
}

.offer-main-info {
	position: relative;
}

.edit-offer-btn {
	width: 4rem;
    height: 2rem;
    margin: 0.5rem 0;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
113.24deg
, #13B497 16.01%, #05737A 106.71%);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
}

.edit-offer-btn svg.img {
    width: 1rem;
    height: 1rem;
}

.search-query {
	margin: 1rem 2.5rem 1rem 1rem;
}

</style>