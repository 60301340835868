<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content" >
          <div class="block">
            <div class="hdr">
              <div class="hdr__l">
                <div class="ttl">Payment Atlas</div>
                <div @click="createPaymentAtlas" class="btn create-legal-service-btn">Create</div>
              </div>
              <div class="hdr__r">
                <div
                  class="btn" :class="{ 'btn--active': showProvidersAtlases }"
                  @click="toggleAtlases"
                >
                  Providers
                </div>
              </div>
            </div>
            <Table @editItem="editPaymentAtlas" @deleteItem="deletePaymentAtlas" class="legal-atlases" :key="paymentAtlasTableKey" :options="paymentAtlasTableData" @change-page="getPaymentAtlases" @scroll-top="toElTop"/>
          </div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Pen from './../components/img/Pen.vue'
import User from './../components/img/User.vue'
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
  components: {
    Pen,
    User,
    Pagination,
    Table,
  },
  data: () => ({
    paymentAtlasTableKey: 0,
    showProvidersAtlases: false,
  }),
  computed: {
    ...mapState({
      paymentAtlasTableData: state => state.payment_atlas.paymentAtlasTableData
    }),
  },
  created () {
    this.getPaymentAtlases()
  },
  methods: {
    ...mapActions({
      getPaymentAtlases: 'payment_atlas/getPaymentAtlases'
    }),
    toggleAtlases () {
      this.showProvidersAtlases = !this.showProvidersAtlases
      this.getPaymentAtlases({
        page: 1,
        showProvidersAtlases: this.showProvidersAtlases
      })
    },
    createPaymentAtlas () {
      this.$store.commit('setModal', {
        template: 'payment-atlas',
        options: {
          id: null,
          actionType: 'create',
          table: 'payment-atlases',
          objectType: 'payment atlas',
          url: 'admin/payment-atlas',
          successfullMessage: 'You successfully created payment atlas',
        }
      })
    },
    editPaymentAtlas (obj) {
      this.$store.commit('setModal', {
        template: 'payment-atlas',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'payment-atlases',
          objectType: 'payment atlas',
          url: 'admin/payment-atlas/update/' + obj.id,
          successfullMessage: 'You successfully updated payment atlas',
        }
      })
    },
    deletePaymentAtlas (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'payment-atlases',
          type: 'payment atlas',
          url: 'admin/payment-atlas/' + obj.id,
          successfullMessage: 'You successfully deleted payment atlas',
        }
      })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-personal {
  .box {
    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_b-info {
      flex-grow: 1;
      position: relative;
      margin-right: 2rem;

      .content {
        .block {
          .hdr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: .75rem;
            margin-bottom: .5rem;

            &__l {
              display: flex;
              align-items: center;
            }

            &__r {
              .btn {
                border-radius: .75rem;
                padding: .5rem 1rem;
                background-color: #3D444B;

                &--active {
                  background-color: #62CDAC;
                }
              }
            }
          }
        }
      }
    }
  }
}

.scale {
  position: relative;
  width: 100%;
  height: 1rem;
  margin: 1rem 0 3.5rem 0;
  border-radius: 3rem;
  background-color: rgba(255, 255, 255, 0.12);

  .progress {
    position: relative;
    height: 100%;
    border-radius: 3rem;
    background-color: #ffffff;
    background-image: url('/img/rate_el.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5rem;
      background-color: #E2F4EC;
      background-image: url('/img/smile.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 65%;
    }

    .note {
      position: absolute;
      bottom: -3.25rem;
      right: 0;
      transform: translateX(50%);
      background-color: #094F4D;
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 7px 1rem 6px;
      border-radius: 6px;

      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: .75rem;
        height: .75rem;
        background-color: #094F4D;
        border-radius: 2px;
      }
    }
  }
}

.btn {
  &-edit {
    width: 2.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
  &-save {
    width: 3.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.form-control {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #1C2126;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: auto 0rem;
  border: 1px solid #2F363D;
  border-radius: .75rem;
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #B9B9B9;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.transparent-box {
  width: 100%;
  border-radius: 14px;
  margin: 1rem 0;
}

.tbl_header .col {
  font-size: 10px !important;
}

.create-legal-service-btn {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  background-color: #62CDAC;
}

.ttl-wrapper {
  width: 400px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  .ttl {
    margin-top: 10px;
  }
}
</style>