<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        {{ options.actionType | capitalize }} {{ options.objectType }}
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group half">
          <label>Name of financial institution</label>
          <input v-model="form.financial_institution_name" type="text" class="form-control" placeholder="EMI">
          <span v-show="formErrors.financial_institution_name" class="invalid-feedback">{{ formErrors.financial_institution_name }}</span>
        </div>
        <div class="form-group half">
          <label>Type of account</label>
          <input v-model="form.account_type" type="text" class="form-control" placeholder="SEPA">
          <span v-show="formErrors.account_type" class="invalid-feedback">{{ formErrors.account_type }}</span>
        </div>
        <div class="form-group">
          <label>
            <input type="checkbox" v-model="form.oif_min_max">
            Open/Integration fee min/max
          </label>
        </div>
        <div class="form-group half">
          <label>Open/Integration fee {{ form.oif_min_max ? 'MIN' : '' }}</label>
          <div class="inpt-block">
            <input v-model="form.open_integration_fee" type="text" class="form-control" placeholder="2.500.00">
            <div class="currency-symbol">€</div>
          </div>
          <span v-show="formErrors.open_integration_fee" class="invalid-feedback">{{ formErrors.open_integration_fee }}</span>
        </div>
        <div v-if="form.oif_min_max" class="form-group half">
          <label>Open/Integration fee MAX</label>
          <div class="inpt-block">
            <input v-model="form.open_integration_fee_max" type="text" class="form-control" placeholder="7.000.00">
            <div class="currency-symbol">€</div>
          </div>
          <span v-show="formErrors.open_integration_fee_max" class="invalid-feedback">{{ formErrors.open_integration_fee_max }}</span>
        </div>
        <div class="form-group" :class="{ half: !form.oif_min_max }">
          <label>Onboarding time</label>
          <div class="inpt-block">
            <input v-model="form.onboarding_time" type="text" class="form-control" placeholder="3">
            <div class="desc">works day</div>
          </div>
          <span v-show="formErrors.onboarding_time" class="invalid-feedback">{{ formErrors.onboarding_time }}</span>
        </div>
        <div class="form-group">
          <label>Incoming fee</label>
          <input v-model="form.incoming_fee" type="text" class="form-control" placeholder="0.2%, min 10.00€, max 100.00€">
          <span v-show="formErrors.incoming_fee" class="invalid-feedback">{{ formErrors.incoming_fee }}</span>
        </div>
        <div class="form-group">
          <label>Outgoing fee</label>
          <input v-model="form.outgoing_fee" type="text" class="form-control" placeholder="0.5%, min 15.00€, max 350.00€">
          <span v-show="formErrors.outgoing_fee" class="invalid-feedback">{{ formErrors.outgoing_fee }}</span>
        </div>
        <div id="risks" class="form-group">
          <label>Risks</label>
          <div class="risks-items">
            <div class="form-check" v-for="(risk, index) in risks" :key="index">
              <input  v-model="form.risks" :value="risk.id" class="form-check-input" type="checkbox">
              <label>{{ (index + 1) }}. {{ risk.name }}</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Country</label>
          <div class="inpt-block">
            <select class="form-control" style="width: 100%;" v-model="form.country_id">
              <option value="null">Select country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
          </div>
          <span v-show="formErrors.country_id" class="invalid-feedback">{{ formErrors.country_id }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import Close from '@/components/img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  created () {
    this.getCountries()
    this.getRisks()
    if (this.options.actionType == 'edit' ) {
      this.getOffer(this.options.id)
    }
  },
  data() {
    return {
      message: '',
      error: '',
      countries: [],
      risks: [],
      form: {
        financial_institution_name: '',
        account_type: '',
        oif_min_max: false,
        open_integration_fee: '',
        open_integration_fee_max: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: '',
        country_id: null,
        risks: [],
        provider: true
      },
      formErrors: {
        financial_institution_name: '',
        account_type: '',
        open_integration_fee: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: '',
      }
    };
  },
  methods: {
    ifRiskIsActive (risk) {
      return true
      /*let isActive = false
      for (let i = 0; i < this.form.risks.length; i++) {
        if (parseInt(risk.id) === parseInt(this.form.risks[i])) {
          console.log(risk.id, this.form.risks[i])
          isActive = true
        }
      }
      return isActive*/
    },
    getCountries() {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/countries')
        .then(function (response) {
          that.countries = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    clearErrors() {
      for (let key in this.form) {
        this.formErrors[key] = ''
      }
    },
    getRisks () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/risks')
        .then(function (response) {
          response.data.sort((a, b) => a.order < b.order ? -1 : (a.order > b.order ? 1 : 0))
          that.risks = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getOffer () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/offers/' + this.options.id, this.form)
      .then(function (response) {
        that.form = response.data
        if (that.form.risks) {
          let arr = []
          for (let i = 0; i < that.form.risks.length; i++) {
            if (that.form.risks[i].id) {
              arr.push(that.form.risks[i].id)
            }
          }
          that.form.risks = arr
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    async submit() {
      const that = this
      this.clearErrors()
      if (this.options.actionType == 'create') {
        this.form.type = (this.options.objectType == 'bank account') ? 1 : 2;
      }
      axios.post(this.options.url, this.form)
        .then(({ data }) => {
          if (data.errors) {
            const errors = data.errors
            for (let key in errors) {
              this.formErrors[key] = errors[key][0]
            }
          }
          if (data.success) {
            this.close()
            that.$store.dispatch('editItemFromTable', {
              id: that.options.id,
              table: that.options.table,
              actionType: that.options.actionType
            })
          }
        })
        .catch(errors => console.dir(errors))
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
}

#risks {
  display: flex;
  flex-direction: column;

  #risks > label {
    margin-bottom: 10px;
  }

  .risks-items {
    margin-top: 10px;
    margin-left: 24px;
  }

  .form-check-input[type=checkbox] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
      float: left;
      margin-left: -1.5em;
  }

  .form-check label {
    margin-left: 5px;
  }

  .form-check-input {
      width: 1em;
      height: 1em;
      margin-top: .25em;
      vertical-align: top;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid rgba(0,0,0,.25);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
  }

  .form-check-input:checked {
      background-color: #13B497;
      border-color: #13B497;
  }

}

.form-control {
  color: var(--c_black);
}

</style>
