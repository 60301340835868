<template>
	<overlay-scrollbars class="page page-personal" ref="os">
      <div class="box-container">
        <div class="transparent-box box_b-info">
          <div class="content">
          	<div class="row">
	          <input v-model="nameOrEmail" class="form-control" type="text" placeholder="Enter name or email">
	        </div>
          	<Table :key="tableKey" :options="tableData" @change-page="getNotifications" @scroll-top="toElTop"/>
          </div>
        </div>
       </div>
	</overlay-scrollbars>
</template>

<script>
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'
import { mapGetters } from 'vuex'
import NotificationText from '@/mixins/notifications_text.js'

export default {
	mixins: [
	  NotificationText
	], 
	components: {
      Pagination,
      Table
    },
	created () {
		this.getNotifications()
	},
	data: () => ({
	    tableKey: 0,
	    nameOrEmail: '',
        filteredUsers: [],
	    tableData: {
	      colsWidth: ['100%'],
	      header: [
	        { ttl: 'Notifications', info: null }
	      ],
	      body: [],
	      paginationData: null,
	      searchDelayTimer: null
	  },
	}),
	mounted () {
		// this.$store.dispatch('notifications/clearGotoAllNotifications')
	},
	computed: {
	    ...mapGetters({
	      gotoAllNotifications: 'notifications/gotoAllNotifications'
	    })
	  },
    watch: {
    	gotoAllNotifications (val) {
	      if (val) {
	        this.getNotifications()
	      }
	    },
	    nameOrEmail (nameOrEmail) {
	    	clearTimeout(this.searchDelayTimer);
		    const that = this
		    this.searchDelayTimer = setTimeout(function() {
		    	that.tableData.body = []
		        that.getNotifications(1)
		    }, 300);   
	    }
    },
	methods: {
		getNotifications (page = 1) {
			const that = this
			let url = 'admin/notifications?page=' + page
			if (this.nameOrEmail) {
				url = url + '&user=' + this.nameOrEmail
			}
			axios.get(url)
		        .then(function (response) {
		          that.tableData.paginationData = response.data
		          that.tableData.body = []
		          response.data.data.forEach(el => {
		          	const text = that.textNotification(el)
		            that.tableData.body.push([
		              { type: 'notification', text: text, notificationType: el.notification.data.type, notification: el}
		            ])
		          })
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
		    this.$store.dispatch('notifications/clearGotoAllNotifications')
		},
	    toElTop (el) {
	      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
	    },
	}

}
</script>

<style type="text/css">
.form-control {
	margin-bottom: 20px !important;
	padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
    height: 58px !important;
}
</style>