<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Edit Provider Business Offer</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Business type</div>
        <div class="data">
          <input type="text" name="business_type" class="inpt" v-model="form.business_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <select name="country_id" class="inpt" v-model="form.country_id">
            <option value="null">Select country</option>
            <option
              v-for="country in countries" :key="country.id"
              :selected="country.id === form.country_id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">License Type</div>
        <div class="data">
          <input type="text" name="license_type" class="inpt" v-model="form.license_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Price</div>
        <div class="data">
          <input type="text" name="price" class="inpt" v-model="form.price">
        </div>
      </div>
      <div class="row">
        <div class="label">Description</div>
        <div class="data">
          <textarea type="text" rows="10" name="description" class="inpt" v-model="form.description" />
        </div>
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from '@/components/img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    countries: [],
    form: {
      business_type: null,
      country: null,
      license_type: null,
      description: null,
      price: null,
      country_id: null
    },
    error: null
  }),
  created () {
    this.getCountries()
  },
  methods: {
    async getCountries () {
      const that = this
      await axios.get(process.env.VUE_APP_BACKEND_API_URL +'admin/data/countries')
        .then(({data}) => {
          console.log('that.form')
          console.log(that.form)
          data.forEach(country => {
            if (country.name == that.form.country) {
              that.form.country_id = country.id
            }
          })
          console.log(that.form.country_id)
          that.countries = data
          that.updateForm()
        })
        .catch(error => console.log(error))
    },
    updateForm () {
      Object.assign(this.form, this.options.form)
    },
    async save () {
      const that = this
      await axios.post(this.options.url, this.form)
          .then(({ data }) => {
            if (data.success) {
              this.close()
              that.$store.dispatch('editItemFromTable', {
                id: that.options.id,
                table: that.options.table,
                actionType: that.options.actionType
              })  
            }
          })
          .catch(error => console.log(error))  
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 100%;
    max-height: 80vh;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    overflow-y: auto;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__desc {
        display: block;

        .label {
          padding-bottom: .25rem;
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}
</style>
