<template>
	<overlay-scrollbars class="page page-personal" ref="os">
      <div class="box-container">
        <div class="transparent-box box_b-info">
          <div class="content">
          	<input class="form-control" type="text" v-model="login" placeholder="">
          	<div class="invalid-feedback">{{ loginError }}</div>
          	<div @click.prevent="submit" class="btn save-btn">Save</div>
          	<div @click.prevent="changePassword" class="btn change-password-btn">Change password</div>
          	<div class="show-all-chats-btn-wrapper"><router-link :to="{ name: 'AllChats' }" class="btn show-all-chats-btn">Show all chats</router-link></div>
          	<div class="show-all-chats-btn-wrapper"><router-link :to="{ name: 'Meta' }" class="btn show-all-chats-btn">Edit meta</router-link></div>
          	<div v-if="$store.state.user.is_super_admin" class="show-all-chats-btn-wrapper"><router-link :to="{ name: 'Industries' }" class="btn show-all-chats-btn">Edit industries</router-link></div>
          	<div class="show-all-chats-btn-wrapper">
				<div class="tmblr">
                    <div 
                    class="btn-tmblr" 
                    :class="customer2Fa ? 'btn-tmblr-r' : 'btn-tmblr-l'"
                    @click="tooglecustomer2Fa"
                    ></div>
                    <div class="txt">Enable customer two factor on login</div>
                  </div>
          	</div>
          	<div class="show-all-chats-btn-wrapper">
				<div class="tmblr">
                    <div 
                    class="btn-tmblr" 
                    :class="provider2Fa ? 'btn-tmblr-r' : 'btn-tmblr-l'"
                    @click="toogleprovider2Fa"
                    ></div>
                    <div class="txt">Enable provider two factor on login</div>
                  </div>
          	</div>
          	<div class="box">
          		<div class="header"></div>
          		<div class="content">
          			<div class="hdr">
          				<div class="ttl">User personal manager</div>
          				<div class="pm-input">
	          				<model-select class="form-control" 
	          				        :options="userEmails"
	                                v-model="personalManagerEmail"
	                                placeholder="Enter personal manager email">
	                        </model-select>
			          		<div v-if="personalManagerEmailError" class="invalid-feedback pm-invalid-feedback">{{ personalManagerEmailError }}</div>
			          	</div>
		          		<div @click="saveDefaultPersonalManager" class="pm-input btn personal-managers-sbmt-btn">Save</div>
          			</div>
	          	</div>
          	</div>
          </div>
        </div>
       </div>
	</overlay-scrollbars>
</template>

<script type="text/javascript">
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

export default {
	mounted () {
	  this.login = this.$store.state.user.member_login
	},
	components: {
      ModelSelect
    },
	data: () => ({
		login: '',
		loginError: '',	
        personalManagerEmail: {
          value: '',
          text: ''
        },
		personalManagerEmailError: '',
		userEmails: [],
		watchPersonalManagerEmails: false,
		customer2Fa: false,
		provider2Fa: false,
	}),
	created () {
	  this.getUsers()
	  this.getSettings()
	  this.getDefaultManager()
	},
	methods: {
		getDefaultManager () {
			const that = this
			axios.get(process.env.VUE_APP_BACKEND_API_URL + 'chat/settings-default-manager')
		        .then(function (response) {
		        	if (response.data) {
		        		that.personalManagerEmail.value = response.data.id
		        		that.personalManagerEmail.text = response.data.email
		        	}
		        })
		        .catch(function (error) {
		          console.log(error);
		        })
		},
		getUsers() {
			const that = this
			axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/all-users-emails')
		        .then(function (response) {
		          that.userEmails = response.data
		        })
		        .catch(function (error) {
		          console.log(error);
		        })
		},
		getSettings() {
			const that = this
			axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/settings')
		        .then(function ({data}) {
		          data.forEach(setting => {
		          	if (setting.code == 'customer_two_factor_on_login') {
		          		that.customer2Fa = +setting.value ? true : false
		          	} else if (setting.code == 'provider_two_factor_on_login') {
		          		that.provider2Fa = +setting.value ? true : false
		          	}
		          })
		        })
		        .catch(function (error) {
		          console.log(error);
		        })
		},
		tooglecustomer2Fa () {
			const that = this
			this.customer2Fa = !this.customer2Fa
			axios.post(process.env.VUE_APP_BACKEND_API_URL + 'admin/settings/change-by-code', {
				code: 'customer_two_factor_on_login',
				value: this.customer2Fa
			})
		        .then(function ({data}) {
		          console.log(data)
		        })
		        .catch(function (error) {
		          console.log(error);
		        })
		},
		toogleprovider2Fa () {
			const that = this
			this.provider2Fa = !this.provider2Fa
			axios.post(process.env.VUE_APP_BACKEND_API_URL + 'admin/settings/change-by-code', {
				code: 'provider_two_factor_on_login',
				value: this.provider2Fa
			})
		        .then(function ({data}) {
		          console.log(data)
		        })
		        .catch(function (error) {
		          console.log(error);
		        })
		},
		resetErrors() {
			this.personalManagerEmailError = ''
		},
	    validatePersonalManagerForm () {
			if (!this.personalManagerEmail.value) {
				this.personalManagerEmailError = 'Please, enter personal manager email'
				return
			}
			this.resetErrors()
	    },
		saveDefaultPersonalManager () {
			this.watchPersonalManagerEmails = true
			this.validatePersonalManagerForm()
			if (this.personalManagerEmailError.length > 0) {
				return
			}
			this.sendPersonalManagerRequest()
		},
		sendPersonalManagerRequest () {
			const that = this
		      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'chat/default-personal-manager', {
		        personal_manager_id: this.personalManagerEmail.value,
		      })
		        .then(function (response) {
		          that.watchPersonalManagerEmails = false
		          that.resetErrors()
		          that.$noty.success('You have successfully saved personal manager')
		        })
		        .catch(function (error) {
		          console.log(error);
		        })
		},
		toElTop (el) {
	      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
	    },
		submit () {
		  const that = this
	      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'admin/admin/update-login', {
	        login: this.login
	      })
	        .then(function (response) {
	          if (response.data.error) {
	            for (let key in response.data.errors) {
	              if (key === 'login' && response.data.errors[key][0]) {
	                that.loginError = response.data.errors[key][0]
	              }
	            }
	            return
	          }
	          that.loginError = ''
	          that.$store.dispatch('setUser')
	          that.$noty.success('You have successfully changed login')
	        })
	        .catch(function (error) {
	          console.log(error);
	        })
		},
		validateEmail(email) {
	        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	        return re.test(String(email).toLowerCase());
	    },
	    changePassword() {
	    	this.$store.commit('setModal', {
	            template: 'admin-change-password',
	        })
	    }
	},
	watch: {
	    'personalManagerEmail.value': function (userEmailValue) {
	      if (this.watchPersonalManagerEmails) {
		      this.validatePersonalManagerForm()
		  }
	    }
	  },
}
</script>

<style lang="scss" scoped>
	.form-control {
	    width: 100%;
	    height: 100%;
	    color: #ffffff;
	    background-color: #1C2126;
	    padding: 1rem;
	    margin: auto 0rem;
	    border: 1px solid #2F363D;
	    border-radius: 0.75rem;
	    box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
	    outline: none;
	    -webkit-appearance: none;
	    width: 500px;
        font-size: 23px;
	}

	.btn {
		font-size: 18px;
	    padding: 1rem 3rem;
	    margin: .25rem 1rem;
	    background: linear-gradient(113.24deg,#13b497 16.01%,#05737a 106.71%);
	    box-shadow: 0 12px 23px rgb(62 73 84 / 4%);
	    border-radius: 6px;
	}

	.save-btn {
		margin-top: 20px;
		width: 143px;
		margin-left: 0rem;
	}

	.change-password-btn {
		margin: 50px 0;
		width: 214px;
        padding: 0.5rem 1.5rem;
	}

	.show-all-chats-btn {
		margin-left: 0px;
		width: 180px;
		padding: 0.5rem 1.5rem;
	}

	.show-all-chats-btn-wrapper {
		margin-bottom: 60px;
	}

	.invalid-feedback {
	    width: 100%;
	    margin-top: 0.25rem;
	    font-size: 20px;
	    color: #dc3545;
	}

	.personal-managers-btn {
		margin-top: 60px;
	    width: 400px;
	    margin-left: 0rem;
	}

	.personal-managers-block {
		margin-top: 15px;
	}

	.personal-managers-sbmt-btn {
		margin-top: 10px;
	    width: 150px;
	    margin-left: 0rem;
	}

	.pm-input {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.pm-invalid-feedback {
		margin-top: 0px;
		margin-bottom: 10px;
	}

.tmblr {
  display: flex;
  align-items: center;
  padding: 0 .25rem;

  .txt {
    color: rgba(255, 255, 255, 0.5);
  }

  .btn {
    &-tmblr {
      position: relative;
      width: 1.5rem;
      height: .5rem;
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 2rem;
      margin: auto 1rem;

      &-l::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }

      &-r::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }
  }

  &-hide {
    .txt {
      color: #62CDAC;
    }

    .btn-tmblr-r::before {
      background-color:  #62CDAC;
    }
  }
}
</style>