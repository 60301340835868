<template>
  <div>
    <div id="header">
      <div class="left">
        <div class="title">
          <ArrowFlat class="img"/>
          <div v-if="$route.meta.isChat" class="chat-wrapper">
            <div class="chat-img">
              <img src="/img/hand.png"></img>
            </div>
            <div>
              KYC Touch
              <div class="chat-desc">direct connection with providers</div>
            </div>
          </div>
          <span v-else>{{ $route.meta.desc }}</span>
        </div>
      </div>
      <div class="right">
        <!-- <router-link :to="{ name: 'Request' }" class="btn btn-settings">
          <Settings class="img"/>
        </router-link> -->
        <Notifications />
        <Messages />
        <div
          class="profile" :class="{ 'open': dropdown }"
          @click="dropdown = !dropdown" v-click-outside="dropdownClose"
        >
          <div class="ava" :style="{ 'background-image': 'url(' + logo + ')' }"></div>
          <div class="name">{{ ($store.state.user) ? $store.state.user.member_login : '' }}</div>
          <ArrowFlat class="arrow"/>
          <div class="list">
            <router-link :to="{ name: 'MySettings' }" href="#" class="item">My settings</router-link>
            <a @click="logout" href="#" class="item">Logout</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$route.meta.breadcrumbs" id="breadcrumbs">
      <div id="breadcrumb-content">
        <span v-for="(breadcrumb, index) in $route.meta.breadcrumbs" :key="index">
          <router-link v-if="breadcrumb.type === 'link'" :to="{ name: breadcrumb.routeName }" class="breadcrumb-link">{{ breadcrumb.text }}</router-link>
          <span v-else>{{ breadcrumb.text }}</span>
          <span class="breadcrumb-divider" v-if="index != $route.meta.breadcrumbs.length - 1">/</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowFlat from './../components/img/ArrowFlat.vue'
import Search from './../components/img/Search.vue'
import Settings from './../components/img/Settings.vue'
import Chat from './../components/img/Chat.vue'
import Notifications from './../components/common/notifications/notifications.vue'
import Messages from './../components/common/messages/messages.vue'

export default {
  components: {
    ArrowFlat,
    Search,
    Settings,
    Chat,
    Notifications,
    Messages
  },
  data() {
      return {
          logo: '',
          dropdown: false
      }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      window.location.href = '/'
    },
    dropdownClose() {
      this.dropdown = false
    }
  },
  watch: {
    '$store.state.user': function() {
      this.logo = (this.$store.state.user.logo) ? process.env.VUE_APP_BACKEND_URL + '/storage/images/users/logos/thumbs/' +  this.$store.state.user.logo : '/img/default-avatar.png';
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  --left_offset: 2rem;

  position: relative;
  left: -2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: calc(100% + var(--left_offset));
  max-width: calc(100% + var(--left_offset));
  min-height: var(--header_height);
  padding: .5rem 2.5rem;
  padding-left: calc(2.5rem + var(--left_offset));
  background-color: #24292E;

  @media (max-width: 1366px) {
    padding: .5rem 1rem;
    padding-left: calc(1rem + var(--left_offset));
  }

  .btn, a.btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: .75rem;
    margin: auto .5rem;
    background-color: #2F363D;

    .img {
      width: 1.5rem;
      height: 1.5rem;
    }

    &_mark {
      ::v-deep svg.img path {
        fill: #62CDAC;
      }
    }

    &_mark::before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 1rem;
      height: 1rem;
      background-color:#62CDAC;
      border-radius: 50%;
    }

    &-settings {
      background-color: #62CDAC;
      margin-right: 1.5rem;

      @media (max-width: 1024px) {
        margin-right: .5rem;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 1rem;
        height: 1rem;
        margin: .5rem;
      }

      .desc {
        font-size: 1.75rem;
        font-weight: 600;
        margin: .5rem 1rem;
        padding-left: .5rem;
        white-space: nowrap;

        @media (max-width: 1366px) {
          margin: .5rem 0;
          font-size: 1.5rem;
        }

        @media (max-width: 1024px) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search {
      flex-grow: 1;
      position: relative;
      height: 54px;
      // width: 26rem;
      // width: 25vw;
      max-width: 26rem;
      margin: auto 1rem;

      input#search {
        width: 100%;
        height: 100%;
        color: #ffffff;
        background-color: #1C2126;
        padding: .5rem 3.25rem .5rem 1.25rem;
        margin: auto .5rem;
        border: 1px solid #2F363D;
        border-radius: .75rem;
        box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
        outline: none;
        -webkit-appearance: none;

        &::placeholder {
          color: #B9B9B9;
        }
      }

      .img {
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .profile {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid #2F363D;
      border-radius: .75rem;
      margin-left: 1rem;
      cursor: pointer;

      @media (max-width: 1024px) {
        margin-left: .5rem;
      }

      .ava {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: .75rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .name {
        margin: 0 .25rem 0 .75rem;
        padding: .25rem .5rem;
      }

      .arrow {
        width: .75rem;
        height: .75rem;
        margin: 0 1rem 0 .25rem;
        transform: rotate(90deg);
      }

      .list {
        display: none;
        position: absolute;
        top: 100%;
        right: -1px;
        min-width: 100%;
        min-width: calc(100% + 2px);
        text-align: right;
        border: 1px solid #2F363D;
        border-top: none;
        border-bottom-left-radius: .75rem;
        border-bottom-right-radius: .75rem;
        background-color: #1c2126;
        z-index: 2;

        .item {
          padding: 1rem;
          text-decoration: none;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          &:last-child {
            border-bottom-left-radius: .75rem;
            border-bottom-right-radius: .75rem;
          }

          &:hover {
            background-color: #2f363d;
          }
        }
      }

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #1c2126;

        .ava {
          border-bottom-left-radius: 0;
        }

        .arrow {
          transform: rotate(-90deg);
        }

        .list {
          display: block;
        }
      }
    }
  }
}

.chat-wrapper {
  display: flex;
  flex-direction: row;
}

.chat-desc {
  font-size: 12px;
}

.chat-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
}

.chat-img img {
  width: 30px;
  height: 30px;
}
</style>
