<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content" >
          <div class="block">
            <div class="hdr">
              <div class="ttl-wrapper">
                <div class="ttl">Legal Net</div>
                <div @click="createLegalService" class="btn create-legal-service-btn">Create</div>
                <div @click="showProvidersServices = !showProvidersServices" class="btn show-providers-services"
                :class="showProvidersServices ? 'show-providers-services-active' : 'show-providers-services-not-active'">
                  Provider
                </div>
              </div>
            </div>
            <Table
              class="legal-atlases"
              :options="legalAtlasTableData" :key="legalAtlasTableKey"
              @editItem="editLegalAtlas"
              @deleteItem="deleteLegalAtlas"
              @change-page="getLegalServices"
              @scroll-top="toElTop"
              @sort="sortBy"
            />
          </div>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Pen from './../components/img/Pen.vue'
import User from './../components/img/User.vue'
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
  components: {
    Pen,
    User,
    Pagination,
    Table,
  },
  data: () => ({
    legalAtlasTableKey: 0,
    legalAtlasTableData: {
      objName: 'legalAtlasTableData',
      colsWidth: ['12%', '12%', '12%', '10%', '14%', '10%', '8%'],
      header: [
        { ttl: 'Name of Legal Co.', info: null, sort: 'legal_name' },
        { ttl: 'Country'},
        { ttl: 'Type of Services', info: null, sort: 'services_type' },
        { ttl: 'Price', info: null, sort: 'price' },
        { ttl: 'Description of Benefit', info: null },
        { ttl: 'Service time', info: null, sort: 'service_time' },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
    showProvidersServices: false,
  }),
  created () {
    this.getLegalServices()
    const that = this
  },
  methods: {
    editLegalAtlas (obj) {
      this.$store.commit('setModal', {
        template: 'legal-atlas',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'legal-atlases',
          objectType: 'legal atlas',
          url: 'admin/legal-atlas/update/' + obj.id,
          successfullMessage: 'You successfully updated legal atlas',
        }
      })
    },
    createLegalService () {
      this.$store.commit('setModal', {
        template: 'legal-atlas',
        options: {
          id: null,
          actionType: 'create',
          table: 'legal-atlases',
          objectType: 'legal atlas',
          url: 'admin/legal-atlas',
          successfullMessage: 'You successfully created legal atlas',
        }
      })
    },
    deleteLegalAtlas (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'legal-atlases',
          type: 'legal atlas',
          url: 'admin/legal-atlas/' + obj.id,
          successfullMessage: 'You successfully deleted legal atlas',
        }
      })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    getLegalServices (page = 1) {
      const that = this
      const basePath = 'admin/legal-atlas?page=' + page
      const sortParams = '&sort=' + this.legalAtlasTableData.sort.name + '&order=' + this.legalAtlasTableData.sort.order + '&providers=' + this.showProvidersServices + '&admin=' + true
      axios.get(basePath + sortParams)
        .then(function (response) {
          that.legalAtlasTableData.paginationData = response.data
          that.legalAtlasTableData.body = []
          response.data.data.forEach(el => {
            if (!that.showProvidersServices) {
              that.legalAtlasTableData.body.push([
                el.legal_name,
                el.country ? el.country.name : '',
                el.services_type,
                el.price,
                el.benefit_description,
                el.service_time,
                { type: 'edit-delete-actions', id: el.id }
              ])
            } else {
              that.legalAtlasTableData.body.push([
                el.legal_name,
                el.country ? el.country.name : '',
                el.services_type,
                el.price,
                el.benefit_description,
                el.service_time,
                el.provider.id,
                (el.provider.member_login) ? el.provider.member_login : el.provider.email,
                { type: 'user-details-delete-actions', id: el.id, userId: el.provider.id }
              ])
            }
          })
          if (that.legalAtlasTableData.sort.name) that.legalAtlasTableData.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    updateCurrentPage(tableName, actionType) {
      let currentPage = 1
      if (actionType == 'edit' || actionType == 'delete') {
        try {
          currentPage = document.getElementsByClassName(tableName)[0]
          .getElementsByClassName('pagination')[0]
          .getElementsByClassName('active')[0]
          .getElementsByClassName('page-link')[0]
          .innerText.trim().replace( /\D+/g, '')
        } catch (e) {
           console.log(e)
        }
      }
      if (tableName == 'legal-atlases') {
        this.getLegalServices(currentPage)
      }
    },
    sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort
      this.getLegalServices(1)
    },
  },
  watch: {
    '$store.state.deleteItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    },
    '$store.state.editItemFromTableData': function(data) {
        this.updateCurrentPage(data.table, data.actionType)
    },
    showProvidersServices: function(val) {
      this.legalAtlasTableData.body = []
      if (val) {
        this.legalAtlasTableData.colsWidth = ['9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%']
        this.legalAtlasTableData.header.splice(6, 0, { ttl: 'Provider Id', info: null, provider: true})
        this.legalAtlasTableData.header.splice(7, 0, { ttl: 'Provider', info: null, provider: true})
      } else {
        this.legalAtlasTableData.colsWidth = ['12%', '12%', '12%', '10%', '14%', '10%', '8%']
        let i = this.legalAtlasTableData.header.length
        while (i--) {
            if (this.legalAtlasTableData.header[i].provider) { 
                this.legalAtlasTableData.header.splice(i, 1);
            } 
        }
      }
      let currentPage = 1
      try {
        currentPage = document.getElementsByClassName(tableName)[0]
        .getElementsByClassName('pagination')[0]
        .getElementsByClassName('active')[0]
        .getElementsByClassName('page-link')[0]
        .innerText.trim().replace( /\D+/g, '')
      } catch (e) {
         console.log(e)
      }
      this.getLegalServices(currentPage)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-personal {
  .box {
    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_rate {
      position: relative;
      height: 7rem;
      height: 11vh;
      margin-top: 6rem;
      margin-top: 7vh;
      margin-bottom: 4rem;
      margin-bottom: 6vh;

      .box-inside {
        width: 95%;
        height: 11rem;
        height: calc(100% + 6vh);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 14px;
        display: flex;

        .content {
          flex-grow: 1;

          .scale-box {
            width: 100%;
          }


          .block {
            margin-bottom: 1.5rem;

            .hdr {
              display: flex;
              align-items: center;
              margin-left: .75rem;
              margin-bottom: .5rem;

              svg.img {
                width: 1.75rem;
                height: 1.75rem;
                margin-right: .5rem;
              }

              .ttl {
                padding: .25rem .5rem;
              }
            }
          }
        }

        .img {
          height: 14rem;
          height: calc(100% + 6vh);
          position: relative;
          top: 100%;
          transform: translateY(-96%);
          margin: auto 3rem;
          margin: auto 3vw;
        }
      }
    }

    &_my-profile {
      position: relative;
      width: 100%;
      padding-right: 4.25rem;

      .header {
        display: flex;

        .title {
          flex-grow: 1;
        }

        .tmblr {
          display: flex;
          align-items: center;
          padding: 0 .25rem;

          .txt {
            color: rgba(255, 255, 255, 0.5);
          }

          .btn {
            &-tmblr {
              position: relative;
              width: 1.5rem;
              height: .5rem;
              background-color: rgba(255, 255, 255, 0.12);
              border-radius: 2rem;
              margin: auto 1rem;

              &-l::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }

              &-r::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }
            }
          }

          &-hide {
            .txt {
              color: #62CDAC;
            }

            .btn-tmblr-r::before {
              background-color:  #62CDAC;
            }
          }
        }
      }

      .content {
        padding: 0 0 1rem 0;
        padding: 0 0 1.5vh 0;
        display: flex;

        &.blur {
          filter: blur(7px);
        }

        .card {
          &-big {
            width: 100%;
            height: 11.5rem;
            display: flex;
            align-items: flex-end;
            background-image: url('/img/two_line.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 1.25rem 1.75rem;

            svg.img {
              width: 3.5rem;
              height: 4rem;
              margin-right: 1rem;
            }

            .data {
              flex-grow: 1;

              .ttl {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                padding-bottom: .25rem;
              }

              .desc {
                font-size: 1.25rem;
                font-weight: 600;
              }
            }
          }
        }

        .col {
          &-1 {
            width: 27rem;
            width: 21vw;
            padding-right: .5rem;
          }

          &-2 {
            flex-grow: 1;
            padding-left: .5rem;
            padding-right: .5rem;
            display: flex;

            .subcol {
              padding: 0 .5rem;

              &-1 {
                width: 30%;
              }

              &-2,
              &-3 {
                width: 35%;
              }
            }
          }
        }
      }
    }

    &_b-info {
      flex-grow: 1;
      position: relative;
      // background-color: #272E35;
      margin-right: 2rem;

      .person-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .header {
        padding-right: 4.25rem;

        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .content {
        .row {
          display: flex;
          justify-content: space-between;

          .card {
            background: rgba(19, 180, 151, 0.1);
            width: 30%;

            &-min {
              width: 20%;
            }
          }
        }
      }
    }

    &_comp-logo {
      width: 23rem;
      position: relative;
      background-color: #272E35;
      overflow: hidden;
      padding-bottom: 1rem;

      .dots {
        position: absolute;

        &-top {
          top: -5px;
          right: 7px;
          width: 2rem;
          opacity: .5;
        }

        &-left {
          top: 6.5rem;
          left: 28px;
          width: 1.5rem;
          opacity: .5;
        }

        &-bottom {
          bottom: -12px;
          right: 1.75rem;
          width: 2.5rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .header {
        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .logo {
        flex-grow: 1;
        width: 14rem;
        height: 14rem;
        margin: .25rem auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        position: relative;
        z-index: 1;

        .btn {
          width: 3.25rem;
          height: 3.25rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &_update {
            background-color: #62CDAC;

            svg {
              width: 1.75rem;
              height: 1.75rem;
            }
          }

          &_remove {
            background-color: #B03636;

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }
}

.scale {
  position: relative;
  width: 100%;
  height: 1rem;
  margin: 1rem 0 3.5rem 0;
  border-radius: 3rem;
  background-color: rgba(255, 255, 255, 0.12);

  .progress {
    position: relative;
    height: 100%;
    border-radius: 3rem;
    background-color: #ffffff;
    background-image: url('/img/rate_el.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5rem;
      background-color: #E2F4EC;
      background-image: url('/img/smile.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 65%;
    }

    .note {
      position: absolute;
      bottom: -3.25rem;
      right: 0;
      transform: translateX(50%);
      background-color: #094F4D;
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 7px 1rem 6px;
      border-radius: 6px;

      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: .75rem;
        height: .75rem;
        background-color: #094F4D;
        border-radius: 2px;
      }
    }
  }
}

.btn {
  &-edit {
    width: 2.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
  &-save {
    width: 3.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.form-control {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #1C2126;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: auto 0rem;
  border: 1px solid #2F363D;
  border-radius: .75rem;
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #B9B9B9;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.transparent-box {
  width: 100%;
  border-radius: 14px;
  margin: 1rem 0;
}

.tbl_header .col {
  font-size: 10px !important;
}

.create-legal-service-btn {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  background-color: #62CDAC;
}

.ttl-wrapper {
  width: 400px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  .ttl {
    margin-top: 10px;
  }
}

.show-providers-services {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  position:absolute;
  right: 3px;
  top: 0;

  &-active {
    background-color: #62CDAC;
  }

  &-not-active {
    background-color: #2F363D;
  }
}
</style>