export default ({
  namespaced: true,
  state: () => ({
    legalProvidersTableData: {
      colsWidth: ['9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'],
      header: [
        { ttl: 'Cust. ID', info: null },
        { ttl: 'Date Join', info: null },
        { ttl: 'Legal Provider Name', info: null },
        { ttl: 'Rating', info: null },
        { ttl: 'KYB Rate', info: null },
        { ttl: 'Type cust.', info: null },
        { ttl: 'Location', info: null },
        { ttl: 'Last Request', info: null },
        { ttl: 'Balance', info: null },
        { ttl: 'Discount code', info: null },
        { ttl: 'Registrar' },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
    },
    legalProvidersInfo: {
      total: '',
      fintechUsersCount: '',
      standartUsersCount: '',
      newUsersCount: '',
      kybRateCount: ''
    },
  }),
  actions: {
    async getLegalProviders ({ commit }, payload) {
      let pageStr = payload && payload.page ? payload.page : (typeof payload == 'number') ? payload : 1;  
      await axios.get('admin/legal-provider?page=' + pageStr)
        .then(({ data }) => {
          commit('setLegalProviders', { data: data });
        })
    },
    async getLegalProvidersInfo ({ commit }, payload) {
      await axios.get('admin/legal-provider/info')
        .then(({ data }) => {
          commit('setLegalProvidersInfo', { data: data });
        })
        .catch(function (error) {
          console.log(error);
        })
    },
  },
  mutations: {
    setLegalProviders (state, payload) {
      state.legalProvidersTableData.paginationData = payload.data
      state.legalProvidersTableData.body = []
      payload.data.data.forEach(el => {
        const isNewHtml = (el.payment_settings && el.payment_settings.payment_request_settings_changed) ? '<span class="badge  new-request-badge">New</span>' : ''
        const requestHtml = (el.request_changed_date) ? el.request_changed_date + isNewHtml : ''
        let balanceItem = (el.balance.trim().length !== 0 && el.balance) ? that.number_format(el.balance, 2, '.', ' ') : ''
        if (el.balance_currency) {
          balanceItem = balanceItem + ' ' + el.balance_currency.code;
        }
        let name = el.full_name
        const kybRate = (parseInt(el.is_kyb_rate_manual) == 1) ? el.kyb_rate_manual : el.kyb_rate 
        if (!name && typeof el.company_full_name !== 'undefined') {
          name = el.company_full_name
        }
        state.legalProvidersTableData.body.push([
          el.serial_number,
          el.created_at_text,
          name,
          { type: 'rating', rating: el.rating, rating_color: el.rating_color },
          kybRate,
          el.customer_type_name,
          el.location,
          { type: 'request-change', html: requestHtml },
          balanceItem,
          el.discount_code,
          el.reg_admin ? 'Admin' : 'User',
          { type: 'edit-delete-actions', id: el.id }
        ])
      })
    },
    setLegalProvidersInfo (state, payload) {
      state.legalProvidersInfo = payload.data
    },
  }
})
