export default ({
  namespaced: true,
  state: () => ({
    paymentAtlasTableData: {
      colsWidth: [],
      header: [],
      body: [],
      paginationData: null,
    },
    adminAtlases: {
      colsWidth: ['5%', '14%', '14%', '14%', '14%', '14%', '8%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name of Bank' },
        { ttl: 'Country of Bank' },
        { ttl: 'Type of Account' },
        { ttl: 'Transaction History' },
        { ttl: 'Balance' },
        { ttl: '' },
      ],
    },
    providerAtlases: {
      colsWidth: ['5%', '14%', '14%', '14%', '14%', '14%', '14%', '8%'],
      header: [
        { ttl: '#' },
        { ttl: 'Provider' },
        { ttl: 'Name of Bank' },
        { ttl: 'Country of Bank' },
        { ttl: 'Type of Account' },
        { ttl: 'Transaction History' },
        { ttl: 'Balance' },
        { ttl: '' },
      ],
    },
  }),
  actions: {
    getPaymentAtlases ({ commit }, payload = { page: 1 }) {
      const providers = payload.showProvidersAtlases ? '&providers=true' : ''
      axios.get(process.env.VUE_APP_BACKEND_API_URL +'admin/payment-atlas?page='+ payload.page + providers)
        .then(({ data }) => {
          commit('setPaymentAtlases', { data: data, providers: payload.showProvidersAtlases })
        })
        .catch(err => console.dir(err))
    },
  },
  mutations: {
    setPaymentAtlases (state, payload) {
      state.paymentAtlasTableData.paginationData = payload.data
      state.paymentAtlasTableData.body = []
      payload.data.data.forEach(el => {
        const name = !el.provider_id && !el.is_legal_company
          ? el.bank_name
          : {
              type: 'info',
              html: el.bank_name,
              info: {
                pos: 'tr',
                txt: 'Provided by Legal company'
              },
            }

        if (payload.providers && el.provider) {
          state.paymentAtlasTableData.colsWidth = state.providerAtlases.colsWidth
          state.paymentAtlasTableData.header = state.providerAtlases.header

          state.paymentAtlasTableData.body.push([
            el.id,
            {
              type: 'v_link',
              html: el.provider.full_name || 'noname',
              link: { name: "CustomerDetail", params: { id: el.provider_id }}
            },
            name,
            el.country ? el.country.name : '',
            el.account_type,
            el.transaction_history,
            +el.balance,
            { type: 'edit-delete-actions', id: el.id }
          ])
        } else {
          state.paymentAtlasTableData.colsWidth = state.adminAtlases.colsWidth
          state.paymentAtlasTableData.header = state.adminAtlases.header

          state.paymentAtlasTableData.body.push([
            el.id,
            name,
            el.country ? el.country.name : '',
            el.account_type,
            el.transaction_history,
            +el.balance,
            { type: 'edit-delete-actions', id: el.id }
          ])
        }
      })
    }
  }
})