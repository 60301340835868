<template>
	<overlay-scrollbars class="page page-customer-detail">
	    <div class="box-container">
			<div class="transparent-box box_b-info">
				<div class="content">
					<div class="row customer-detail-info">
						<div v-if="!$route.params.isProvider" @click="showRequestModal" class="card clickable">
							<div class="desc">Customer Request
								<span v-if="customerRequestIsNew" class="badge  new-request-badge">New</span></div>
						</div>
						<div @click="showUploadedFilesModal" class="card clickable">
							<div class="desc">Uploaded Files</div>
						</div>
						<div v-if="!$route.params.isProvider" @click="showDownloadInvoiceModal" class="card clickable">
							<div class="desc">Download Invoice</div>
						</div>
						<div v-if="!$route.params.isProvider" @click="showAppliedOfferModal" class="card clickable">
							<div class="desc">Applied Offer</div>
						</div>
						<div v-if="!$route.params.isProvider" @click="showCreateIndivualOfferModal" class="card clickable">
							<div class="desc">Send individual offer</div>
						</div>
					</div>
					<div class="row customer-status-info">
						<div class="card">
              <div class="data">
                 <div class="desc">Balance: {{ customer.balance }} {{ customer.balance_currency }}</div>
              </div>
						</div>
						<div class="card">
							<Stat class="img"/>
              <div v-if="!showStatusInput" class="data">
                 <div class="ttl">Status</div>
                 <div v-if="showPaidStatusText" class="desc">{{ (customer.is_paid) ? 'Paid' : 'Unpaid' }}</div>
              </div>
              <select class="form-control" v-if="showStatusInput" v-model="status">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
              </select>
              <span v-if="!showStatusInput" @click="showStatusInput = !showStatusInput">
                <Pen class="appendImg"/>
              </span>
              <div @click="saveStatus" class="btn btn-save-status" v-if="showStatusInput">
                Save
              </div>
						</div>
						<div class="card">
							<Stat class="img"/>
		            <div v-if="!showRiskInput" class="data">
		               <div class="ttl">Pre-check type of risk</div>
		               <div v-if="showRiskStatusText" class="desc">
                     <span v-if="+customer.show_calculated_risk == 0">{{ customer.risk }}</span>
                     <span v-else>{{ customer.calculatedRiskName }}</span>
                   </div>
		            </div>
                <div v-if="showRiskInput" class="risk-edit-block">
                  <span class="calculated-risk">Calculated Risk: {{ customer.calculatedRiskName }}</span>
                  <div class="manual-risk-select-wrapper"> Manual Risk:
                    <select class="form-control risk-form-control" v-model="riskId">
                        <option value="null">Select risk</option>
                        <option v-for="risk in risks" :key="risk.id" :value="risk.id">{{ risk.name }}</option>
                    </select>
                  </div>
                  <div class="tmblr">
                    <div
                      class="btn btn-tmblr"
                      :class="+customer.show_calculated_risk == 0 ? 'btn-tmblr-r' : 'btn-tmblr-l'"
                      @click="toogleShowCalculatedRisk"
                    ></div>
                    <div class="txt">Manual</div>
                  </div>
                </div>
		            
		            <span v-if="!showRiskInput" @click="showRiskInput = !showRiskInput">
		            	<Pen class="appendImg"/>
		            </span>
		            <div @click="saveRisk" class="btn btn-save-status" v-if="showRiskInput">
		            	Save
		            </div>
						</div>
            <div class="card">
              <Stat class="img"/>
              <div v-if="!showRatingInput" class="data">
                 <div class="ttl">Rating</div>
                 <div v-if="showPaidStatusText" class="rating-color" :style="{'background-color': customer.rating_color, 'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}">{{ customer.rating }}</div>
              </div>
              <div v-if="showRatingInput" class="row" style="flex-direction: column">
                <input class="form-control rating-input"  @input="onRatingChange" type="number" v-model="customer.rating" placeholder="Rating">
                <compact-picker
                  v-model="ratingColor"
                  :palette="ratingPallete" />
              </div>
              <span v-if="!showRatingInput" @click="showRatingInput = !showRatingInput">
                <Pen class="appendImg"/>
              </span>
              <div @click="saveRating" class="btn btn-save-status" v-if="showRatingInput">
                Save
              </div>
            </div>
            <div class="card">
                <div class="data" v-if="!showKybRateManual">
                  <div>KYB Rate: {{ isKybRateManual ? customer.kyb_rate_manual : customer.kyb_rate }}</div>
                  <div>Manual: {{ isKybRateManual ? 'Yes' : 'No' }}</div>
                </div>
                <span v-if="!showKybRateManual" @click="showKybRateManual = !showKybRateManual">
                  <Pen class="appendImg"/>
                </span>
                <div v-if="showKybRateManual" class="row" style="flex-direction: column">
                  <label>KYB Rate Manual</label>
                  <input v-if="isKybRateManual" class="form-control rating-input"  type="text" v-model="customer.kyb_rate_manual" placeholder="Rating">
                  <div class="tmblr" :class="{ 'tmblr-hide': !isKybRateManual }">
                    <div
                      class="btn btn-tmblr"
                      :class="!isKybRateManual ? 'btn-tmblr-l' : 'btn-tmblr-r'"
                      @click="toogleKybRateManual"
                    ></div>
                    <div class="txt">Manual</div>
                  </div>
                  <textarea v-model="customer.kyb_rate_comment" rows="5" type="text" placeholder="Comment" class="form-control kyb-rate-comment" />
                  <div @click="saveKybRateManual" class="btn btn-save-status" style="margin-top: 1rem; margin-left: 0rem">
                    Save
                  </div>
                </div>
            </div>
            <div class="card settings-card" @click="changePassword">
              <Settings class="img settings-img"/>
            </div>
					</div>
          <div class="row customer-status-info">
            <div class="card" v-if="$store.state.user.is_super_admin">
              <div v-if="!showChangeAdminRole" class="data">
                 <div class="ttl">Is Admin</div>
                 <div class="desc">{{ (customer.is_admin) ? 'Yes' : 'No' }}</div>
              </div>
              <select v-model="customer.is_admin" class="form-control" v-if="showChangeAdminRole">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
              </select>
              <span v-if="!showChangeAdminRole" @click="showChangeAdminRole = !showChangeAdminRole">
                <Pen class="appendImg"/>
              </span>
              <div @click="saveAdminRole" class="btn btn-save-status" v-if="showChangeAdminRole">
                Save
              </div>
            </div>
            <div class="card">
              <div class="data">
                 <div class="desc">Balance Settings</div>
              </div>
              <span @click="editBalanceSettings">
                <Pen class="appendImg"/>
              </span>
            </div>
            <div class="row customer-status-info">
              <div class="card">
                <div class="data">
                   <div class="desc">Chat</div>
                </div>
                <span @click="editChatSettings">
                  <Pen class="appendImg"/>
                </span>
              </div>
            </div>
          </div>
				</div>
			</div>
	    </div>
	    <div class="box box_my-profile">
        <div v-if="!editCustomerState" class="btn btn-edit" @click="editCustomerState = true">
          <Pen class="img"/>
        </div>
        <div v-if="editCustomerState" class="btn btn-save" @click="saveCustomer">
          Save
        </div>
        <div class="header">
          <div class="title">Profile</div>
        </div>
        <div class="content">
          <div class="col col-1">
            <div class="card card-big" ref="company_full_name">
              <User class="img"/>
              <div class="data">
                <div class="ttl">Company full name</div>
                <div class="desc" v-if="!editCustomerState">{{ customer.company_full_name }}</div>
                  <input name="company_full_name" class="form-control" v-if="editCustomerState" type="text" v-model="customer.company_full_name" placeholder="">
                  <span v-show="customerFormErrors.company_full_name" class="invalid-feedback">{{ customerFormErrors.company_full_name }}</span>
              </div>
            </div>
          </div>
          <div class="col col-2">
            <div class="subcol subcol-1">
              <div class="card card-login" ref="member_login">
                <Lock class="img"/>
                <div class="data">
                  <div class="ttl">Profile login</div>
                  <div v-if="!editCustomerState" class="desc">{{ customer.member_login }}</div>
                  <input class="form-control" v-if="editCustomerState" type="text" v-model="customer.member_login" placeholder="">
                  <span v-show="customerFormErrors.member_login" class="invalid-feedback">{{ customerFormErrors.member_login }}</span>
                </div>
              </div>
              <div class="card card-country" ref="registration_country">
                <Worldwide class="img"/>
                <div class="data" :key="registrationCountry">
                  <div class="ttl">Country of registration</div>
                  <div :key="registrationCountry" v-if="!editCustomerState" class="desc">{{ customer.registration_country_name  }}</div>
                   <select class="form-control" v-if="editCustomerState" v-model="customer.registration_country">
                    <option value="null">Select country</option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="subcol subcol-2">
              <div class="card card-website" ref="website">
                <BrowserWindow class="img"/>
                <div class="data">
                  <div class="ttl">Website</div>
                  <div v-if="!editCustomerState" class="desc">{{ customer.website }}</div>
                  <input class="form-control" v-if="editCustomerState" type="text" v-model="customer.website" placeholder="">
                </div>
              </div>
              <div class="card card-company_reg_number" ref="company_registration_number">
                <ShoppingList class="img"/>
                <div class="data">
                  <div class="ttl">Company registration number</div>
                  <div v-if="!editCustomerState" class="desc">{{ customer.company_registration_number  }}</div>
                  <input class="form-control" v-if="editCustomerState" type="text" v-model="customer.company_registration_number" placeholder="">
                </div>
              </div>
            </div>
            <div class="subcol subcol-3">
              <div class="card card-email" ref="email">
                <Mail class="img"/>
                <div class="data">
                  <div class="ttl">E-mail</div>
                  <div v-if="!editCustomerState" class="desc">{{ customer.email }}</div>
                  <input class="form-control" v-if="editCustomerState" type="text" v-model="customer.email" placeholder="">
                  <span v-show="customerFormErrors.email" class="invalid-feedback">{{ customerFormErrors.email }}</span>
                </div>
              </div>
              <div class="card card-phone" ref="mobile_phone">
                <Telephone class="img"/>
                <div class="data">
                  <div class="ttl">Mobile phone</div>
                  <div v-if="!editCustomerState" class="desc">{{customer.mobile_phone }}</div>
                  <input class="form-control" v-if="editCustomerState" type="text" v-model="customer.mobile_phone" placeholder="">
                  <span v-show="customerFormErrors.mobile_phone" class="invalid-feedback">{{ customerFormErrors.mobile_phone }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="box-container">
      <div class="box box_b-info">
        <div v-if="!editBusinessSettingsState" @click="editBusinessSettingsState = true" class="btn btn-edit">
          <Pen class="img"/>
        </div>
        <div v-if="editBusinessSettingsState" class="btn btn-save" @click="saveBusinessSettings">
          Save
        </div>
        <div class="header">
          <div class="title">Business information</div>
        </div>
        <div class="content">
          <div class="row">
            <div class="card" ref="monthly_turnover">
              <Dollar class="img"/>
              <div class="data" v-if="typeof businessSettingsErrors === 'object' && businessSettingsErrors && typeof businessSettingsErrors.monthly_turnover !== 'undefined'">
                <div class="ttl">Monthly turnover ($)</div>
                <div v-if="!editBusinessSettingsState && businessSettingsForm && typeof businessSettingsForm.monthly_turnover !== 'undefined'" class="desc">{{ businessSettingsForm.monthly_turnover }}</div>
                <input name="monthly_turnover" class="form-control" v-if="editBusinessSettingsState && businessSettingsForm && typeof businessSettingsForm.monthly_turnover !== 'undefined'" type="text" v-model="businessSettingsForm.monthly_turnover" placeholder="">
                <span v-show="businessSettingsErrors.monthly_turnover" class="invalid-feedback">{{ businessSettingsErrors.monthly_turnover }}</span>
              </div>
            </div>
            <div class="card" ref="clients_geo">
              <MapPin class="img"/>
              <div class="data">
                <div class="ttl">Geo of clients</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.clients_geo_name }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.clients_geo">
                    <option value="null">Worldwide</option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                  </select>
              </div>
            </div>
            <div class="card" ref="company_country_origin">
              <MapPin class="img"/>
              <div class="data">
                <div class="ttl">Adress of Substance</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.company_country_origin_name }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.company_country_origin">
                    <option value="null">Worldwide</option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="card card-min" ref="worldwide_licence">
              <DocLicense class="img"/>
              <div class="data">
                <div class="ttl">License</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.worldwide_licence_text }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.worldwide_licence">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="card card-min" ref="worldwide_kyc">
              <DocLicense class="img"/>
              <div class="data">
                <div class="ttl">KYC</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.worldwide_kyc_text }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.worldwide_kyc">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="card" ref="ubo_residential">
              <MapPin class="img"/>
              <div class="data">
                <div class="ttl">UBO residental</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.ubo_residential_name }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.ubo_residential">
                    <option value="null">Worldwide</option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
              </div>
            </div>
            <div class="card card-min" ref="ubo_nominal">
              <BreifcaceTr class="img"/>
              <div class="data">
                <div class="ttl">Director nominal</div>
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.ubo_nominal_text }}</div>
                <select class="form-control" v-if="editBusinessSettingsState" v-model="businessSettingsForm.ubo_nominal">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box box_comp-logo">
        <span class="dots dots-top"><Dots5/></span>
        <span class="dots dots-left"><Dots5/></span>
        <span class="dots dots-bottom"><Dots5/></span>
        <div class="header">
          <div class="title">Photo</div>
        </div>
        <div ref="logo" class="logo" v-bind:style="{ 'background-image': 'url(' + logo + ')' }">
        </div>
      </div>
    </div>
    <div v-if="customer.is_provider">
      <ProvidersReferrals :provider="customer" />
      <LegalAtlases :provider="customer" />
      <div class="provider-info-tables">
        <ProviderAccounts :provider="customer" /> 
        <AdditionalServices :provider="customer" /> 
        <ProviderBusinesOffers :provider="customer" />
      </div>
    </div>
    <div v-else>
      <CustomerReferrals :customer="customer"/> 
    </div>
    <div class="box-container">
          <Table class="notifications_tbl" ref="notifications" :key="notificationTableKey" :options="notificationTableData" @change-page="getNotifications" @scroll-top="toNotificationsTop"/>
    </div>
    </overlay-scrollbars>
</template>

<script type="text/javascript">
import Stat from './../components/img/Stat.vue'
import Pen from './../components/img/Pen.vue'
import User from './../components/img/User.vue'
import Lock from './../components/img/Lock.vue'
import Worldwide from './../components/img/Worldwide.vue'
import BrowserWindow from './../components/img/BrowserWindow.vue'
import ShoppingList from './../components/img/ShoppingList.vue'
import Mail from './../components/img/Mail.vue'
import Telephone from './../components/img/Telephone.vue'
import Dollar from './../components/img/Dollar.vue'
import MapPin from './../components/img/MapPin.vue'
import DocLicense from './../components/img/DocLicense.vue'
import BreifcaceTr from './../components/img/BreifcaceTr.vue'
import Dots5 from './../components/img/Dots5.vue'
import Exchange from './../components/img/Exchange.vue'
import Close from './../components/img/Close.vue'
import { Compact } from 'vue-color'
import Settings from './../components/img/Settings.vue'
import { mapGetters} from 'vuex'
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'
import NotificationText from '@/mixins/notifications_text.js'
import LegalAtlases from './../components/provider/LegalAtlases.vue'
import ProviderAccounts from './../components/provider/ProviderAccounts.vue'
import AdditionalServices from './../components/provider/AdditionalServices.vue'
import ProviderBusinesOffers from './../components/provider/BusinesOffers.vue'
import ProvidersReferrals from './../components/provider/ProvidersReferrals.vue'
import CustomerReferrals from './../components/customer/CustomerReferrals.vue'

export default {
  mixins: [
    NotificationText
  ], 
	components: {
	    Stat,
	    Pen,
	    User,
	    Lock,
	    Worldwide,
	    BrowserWindow,
	    ShoppingList,
	    Mail,
	    Telephone,
	    Dollar,
	    MapPin,
	    DocLicense,
	    BreifcaceTr,
	    Dots5,
	    Exchange,
	    Close,
      'compact-picker': Compact,
      Settings,
      Pagination,
      Table,
      LegalAtlases,  
      ProviderAccounts,
      AdditionalServices,
      ProviderBusinesOffers,
      ProvidersReferrals,
      CustomerReferrals,
	 },
	data: () => ({
    ratingColor: {
      hex: '#ffffff'
    },
    ratingPallete: [],
		customer: {},
		businessSettingsForm: {},
		customerRequestIsNew: false,
		showStatusInput: false,
    showChangeAdminRole: false,
		status: false,
    showPaidStatusText: false,
		showRatingText: false,
    showRiskInput: false,
		showRatingInput: false,
    showKybRateManual: false,
    isKybRateManual: false,
		riskId: null,
		showRiskStatusText: false,
		risks: [],
		logo: '',
		editCustomerState: false,
		editBusinessSettingsState: false,
    notificationTableKey: 0,
    notificationTableData: {
        colsWidth: ['100%'],
        header: [
          { ttl: 'Notifications', info: null }
        ],
        body: [],
        paginationData: null,
    },
    searchDelayTimer: null,
		customerFormErrors: {
	      company_full_name: null,
	      member_login: null,
	      registration_country: null,
	      company_registration_number: null,
	      email: null,
	      mobile_phone: null,
	    },
	    businessSettingsErrors : {
	      clients_geo: null,
	      company_country_origin: null,
	      monthly_turnover: '',
	      ubo_nominal: false,
	      ubo_residential: null,
	      user_id: null,
	      worldwide_kyc: false,
	      worldwide_licence: false,
	    },
	    countries: [],
	    registrationCountry: 0,
	}),
	created () {
    this.changeMeta()
		this.getCustomer()
		this.getRisks()
    this.getCountries()
		this.getRatingColors()
    this.getNotifications()
	},
  watch: {
    viewUpdateProfileFields (notification) {
      if (notification) {
        this.showUpdatedProfilePage(notification.notification.data.updated_fields)
      }
    },
    viewUpdatedBusinessInformationFields (notification) {
      if (notification) {
        this.showUpdatedProfilePage(notification.notification.data.updated_fields)
      }
    },
    viewUpdatedLogo (notification) {
      if (notification) {
        this.goToLogo()
      }
    },
    viewUpdatedRequest () {
      this.showRequestModal()
    },
    applyOffer (notification) {
      this.showAppliedOfferModal()
    },
    cancelOffer (notification) {
      this.showAppliedOfferModal()
    },
    updateCompliance (notification) {
      this.showUploadedFilesModal()
    }
  },
  computed: {
    ...mapGetters({
      viewUpdateProfileFields: 'notifications/viewUpdateProfileFields',
      viewUpdatedBusinessInformationFields: 'notifications/viewUpdatedBusinessInformationFields',
      viewUpdatedLogo: 'notifications/viewUpdatedLogo',
      viewUpdatedRequest: 'notifications/updateRequest',
      applyOffer: 'notifications/applyOffer',
      cancelOffer: 'notifications/cancelOffer',
      updateCompliance: 'notifications/updateCompliance'
    }),
  },
  mounted () {
    if (this.$route.params) {
      if (this.$route.params.viewUpdateProfileFields) {
        this.showUpdatedProfilePage(this.$route.params.viewUpdateProfileFields)
      }
      if (this.$route.params.viewUpdatedBusinessInformationFields) {
        this.showUpdatedProfilePage(this.$route.params.viewUpdatedBusinessInformationFields)
      }
      if (this.$route.params.viewUpdatedLogo) {
        this.goToLogo()
      }
      if (this.$route.params.viewUpdatedRequest) {
        this.showRequestModal()
      }
      if (this.$route.params.viewAppliedOffers) {
        this.showAppliedOfferModal()
      }
      if (this.$route.params.viewCompliance) {
        this.showUploadedFilesModal()
      }
    }
  },
	methods: {
    onRatingChange () {
      if (this.customer.rating < 0) this.customer.rating = 0;
      if (this.customer.rating > 100) this.customer.rating = 100;
    },
    changeMeta() {
      if (this.$route.params.isProvider) {
        this.$route.meta.headerName = 'Legal Provider'
        this.$route.meta.breadcrumbs = [
            {
              type: 'link',
              text: 'Legal Provider',
              routeName: 'LegalProvider'
            },
            {
              text: 'Legal Provider Details'
            }
          ]
      } else if (this.$route.params.isAdmin) {
        this.$route.meta.headerName = 'Administrator'
        this.$route.meta.breadcrumbs = [
            {
              type: 'link',
              text: 'Administrators',
              routeName: 'Admins'
            },
            {
              text: 'Administrator Details'
            }
          ]
      } else {
        this.$route.meta.headerName = 'Customer'
        this.$route.meta.breadcrumbs = [
            {
              type: 'link',
              text: 'Customer',
              routeName: 'Customer'
            },
            {
              text: 'Customer Details'
            }
          ]
      }
    },
    toNotificationsTop (el) {
      this.$refs.notifications.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    toogleKybRateManual () {
      this.isKybRateManual = !this.isKybRateManual
    },
    toogleShowCalculatedRisk () {
      if (+this.customer.show_calculated_risk == 1) {
        this.customer.show_calculated_risk = 0
      } else {
        this.customer.show_calculated_risk = 1
      }
    },
    saveKybRateManual () {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'admin/customers/toogle-kyb-rate-manual/' + this.$route.params.id, {
        is_kyb_rate_manual: this.isKybRateManual,
        kyb_rate_manual: this.customer.kyb_rate_manual,
        kyb_rate_comment: this.customer.kyb_rate_comment,
      })
        .then(function (response) {
          that.showKybRateManual = false
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    goToLogo () {
      const element = this.$refs.logo
      if (element) {
        element.scrollIntoView(false)
      }
    },
    showUpdatedProfilePage (updatedFields) {
      if (updatedFields && Object.keys(updatedFields)[0]) {
        const key = Object.keys(updatedFields)[0]
        const element = this.$refs[key]
        if (element) {
          element.scrollIntoView()
        }
      }
    },
		getCustomer () {
			const that = this
	        axios.get('admin/customers/show/' + this.$route.params.id)
	          .then(function (response) {
              that.ratingColor.hex = (response.data.rating_color) ? response.data.rating_color : '#ffffff'
              response.data.rating = (response.data.rating) ? response.data.rating : ''
	            that.customer = response.data
              that.customer.calculatedRiskName = (typeof that.customer.calculatedRisk === 'object' && that.customer.calculatedRisk !== null) ? that.customer.calculatedRisk.name : ''
	            if (that.customer.payment_request_settings_changed) {
	              that.customerRequestIsNew = true
	            }
	            that.status = (that.customer.is_paid === 1) ? true : false
              that.showPaidStatusText = true
	            that.showRatingText = true
	            that.riskId = that.customer.risk_id
	            that.showRiskStatusText = true
	            that.businessSettingsForm = that.customer.business_settings ? that.customer.business_settings : {}
	            that.logo = (that.customer.logo) ? process.env.VUE_APP_BACKEND_URL + '/storage/images/users/logos/origin/' +  that.customer.logo : '/img/default-avatar.png';
              that.isKybRateManual = (that.customer.is_kyb_rate_manual == '1') ? true : false
	          })
	          .catch(function (error) {
	            console.log(error);
	          })
		},
		saveStatus () {
			const that = this
			axios.post('admin/customers/change-paid-status/' + this.$route.params.id, {
				status: this.status
			})
	          .then(function (response) {
	            if (response.data.success) {
	            	that.customer.is_paid = response.data.is_paid
	            	that.showStatusInput = false
	            }
	          })
	          .catch(function (error) {
	            console.log(error);
	          })
		},
    saveAdminRole () {
      const that = this
      axios.post('admin/change-admin-role/' + this.$route.params.id, {
        is_admin: this.customer.is_admin
      })
            .then(function (response) {
              if (response.data.success) {
                that.customer.is_admin = response.data.is_admin
              }
              that.showChangeAdminRole = false
            })
            .catch(function (error) {
              console.log(error);
            })
    },
		getRisks () {
			const that = this
	        axios.get('admin/data/risks')
	          .then(function (response) {
	            that.risks = response.data
	          })
	          .catch(function (error) {
	            console.log(error);
	          })
		},
		saveRisk () {
			const that = this
			axios.post('admin/customers/change-risk/' + this.$route.params.id, {
				risk_id: this.riskId,
        show_calculated_risk: this.customer.show_calculated_risk,
			})
        .then(function (response) {
          if (response.data.success) {
          	that.customer.risk = response.data.risk
            that.customer.risk_id = response.data.risk_id
          	that.customer.show_calculated_risk = response.data.show_calculated_risk
          	that.showRiskInput = false
          }
        })
        .catch(function (error) {
          console.log(error);
        })
		},
    saveRating () {
      const that = this
      axios.post('admin/customers/update-rating/' + this.$route.params.id, {
        rating_color: this.ratingColor.hex,
        rating: this.customer.rating
      })
        .then(function (response) {
          if (response.data.success) {
            that.customer.rating_color = response.data.rating_color
            that.customer.rating = response.data.rating
            that.showRatingInput = false
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
		getCountries () {
	      const that = this
	      axios.get('admin/data/countries')
	        .then(function (response) {
	          that.countries = response.data
	        })
	        .catch(function (error) {
	          console.log(error);
	        })
	    },
      getRatingColors () {
        const that = this
        axios.get('admin/data/rating-colors')
          .then(function (response) {
            for (let i = 0; i < response.data.length; i++) {
              that.ratingPallete.push(response.data[i].code)
            } 
          })
          .catch(function (error) {
            console.log(error);
          })
      },
	    clearCustomerFormErrors () {
	      for (let key in this.customer) {
	        this.customerFormErrors[key] = null
	      }
	    },
	    saveCustomer () {
	      const that = this
	      this.clearCustomerFormErrors()
	      axios.post('admin/customers/' + this.$route.params.id, this.customer)
	        .then(function (response) {
	          if (response.data.error) {
	            for (let key in response.data.errors) {
	              if (key === 'company_full_name' && response.data.errors[key][0]) {
	                that.customerFormErrors.company_full_name = response.data.errors[key][0]
	              } else if (key === 'email' && response.data.errors[key][0]) {
	                that.customerFormErrors.email = response.data.errors[key][0]
	              } else if (key === 'member_login' && response.data.errors[key][0]) {
	                that.customerFormErrors.member_login = response.data.errors[key][0]
	              } else if (key === 'mobile_phone' && response.data.errors[key][0]) {
	                that.customerFormErrors.mobile_phone = response.data.errors[key][0]
	              }
	            }
	            return
	          }
	          if (response.data.success) {
	          	if (response.data.customer) {
	          		that.customer.registration_country = response.data.customer.registration_country
	          		that.customer.registration_country_name = response.data.customer.registration_country_name
	          		that.registrationCountry++
	          	}
	          	that.clearCustomerFormErrors()
	          	that.editCustomerState = false
	      	  }
	        })
	        .catch(function (error) {
	          console.log(error);
	        })
	    },
	    clearBusinessSettingsErrors () {
	      for (let key in this.businessSettingsErrors) {
	        this.businessSettingsErrors[key] = null
	      }
	    },
	    saveBusinessSettings () {
	      this.clearBusinessSettingsErrors()
	      const that = this
	      axios.post('admin/customers/business-settings/' + this.$route.params.id, this.businessSettingsForm)
	        .then((response) => {
	          if (response.data.error) {
	            for (let key in response.data.errors) {
	              if (key === 'monthly_turnover' && response.data.errors[key][0] && typeof that.businessSettingsErrors === 'object') {
	                that.businessSettingsErrors.monthly_turnover = response.data.errors[key][0]
	              }
	            }
	            return
	          }
	          that.businessSettingsForm.ubo_residential_name = response.data.data.ubo_residential_name
	          that.businessSettingsForm.clients_geo_name = response.data.data.clients_geo_name
	          that.businessSettingsForm.company_country_origin_name = response.data.data.company_country_origin_name

	          that.businessSettingsForm.ubo_nominal = response.data.data.ubo_nominal
	          that.businessSettingsForm.ubo_nominal_text = response.data.data.ubo_nominal_text
	          that.businessSettingsForm.worldwide_kyc = response.data.data.worldwide_kyc
	          that.businessSettingsForm.worldwide_kyc_text = response.data.data.worldwide_kyc_text
	          that.businessSettingsForm.worldwide_licence = response.data.data.worldwide_licence
	          that.businessSettingsForm.worldwide_licence_text = response.data.data.worldwide_licence_text
	          that.editBusinessSettingsState = false
	        })
	        .catch((errors) => {
	          console.dir(errors)
	        })
	    },
      showRequestModal () {
        this.$store.commit('setModal', {
          template: 'request',
          options: {
            id: this.$route.params.id,
            type: 'customer',
            url: 'admin/customers/' + this.$route.params.id,
            successfullMessage: 'You successfully deleted customer',
          }
        })
      },
      showCreateIndivualOfferModal () {
        this.$store.commit('setModal', {
          template: 'create-individual-offer',
          options: {
            id: this.$route.params.id,
          }
        })
      },
      showUploadedFilesModal () {
        this.$store.commit('setModal', {
          template: 'uploaded-files',
          options: {
            id: this.$route.params.id,
            type: 'customer',
            url: 'admin/customers/' + this.$route.params.id,
            successfullMessage: 'You successfully deleted customer',
          }
        })
      },
      showAppliedOfferModal () {
        this.$store.commit('setModal', {
          template: 'applied-offer',
          options: {
            id: this.$route.params.id,
            type: 'customer',
            url: 'admin/customers/' + this.$route.params.id,
            successfullMessage: 'You successfully deleted customer',
          }
        })
      },
      showDownloadInvoiceModal () {
        this.$store.commit('setModal', {
          template: 'download-invoice',
          options: {
            id: this.$route.params.id,
            type: 'customer',
            url: 'admin/customers/' + this.$route.params.id,
            successfullMessage: 'You successfully deleted customer',
          }
        })
      },
      changePassword () {
          this.$store.commit('setModal', {
            template: 'change-password',
            options: {
              customerId: this.$route.params.id
            }
        })
      },
      editBalanceSettings () {
        this.$store.commit('setModal', {
            template: 'balance-settings',
            options: {
              customerId: this.$route.params.id
            }
        })
      },
      editChatSettings () {
        this.$store.commit('setModal', {
            template: 'common-chat-settings',
            options: {
              customerId: this.$route.params.id
            }
        })
      },
      getNotifications (page = 1) {
        const that = this
        axios.get('admin/notifications?page=' + page + '&user_id=' + this.$route.params.id)
              .then(function (response) {
                that.notificationTableData.paginationData = response.data
                that.notificationTableData.body = []
                response.data.data.forEach(el => {
                  const text = that.textNotification(el)
                  that.notificationTableData.body.push([
                    { type: 'notification', text: text, notificationType: el.notification.data.type, notification: el}
                  ])
                })
              })
              .catch((errors) => {
                console.dir(errors)
              })
      },
	}
}
</script>

<style lang="scss" scoped>
.page-customer-detail {
  .box {
    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_rate {
      position: relative;
      height: 7rem;
      height: 11vh;
      margin-top: 6rem;
      margin-top: 7vh;
      margin-bottom: 4rem;
      margin-bottom: 6vh;

      .box-inside {
        width: 95%;
        height: 11rem;
        height: calc(100% + 6vh);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 14px;
        display: flex;

        .content {
          flex-grow: 1;

          .scale-box {
            width: 100%;
          }
        }

        .img {
          height: 14rem;
          height: calc(100% + 6vh);
          position: relative;
          top: 100%;
          transform: translateY(-96%);
          margin: auto 3rem;
          margin: auto 3vw;
        }
      }
    }

    &_my-profile {
      position: relative;
      width: 100%;
      padding-right: 4.25rem;

      .header {
        display: flex;

        .title {
          flex-grow: 1;
        }

        .tmblr {
          display: flex;
          align-items: center;
          padding: 0 .25rem;

          .txt {
            color: rgba(255, 255, 255, 0.5);
          }

          .btn {
            &-tmblr {
              position: relative;
              width: 1.5rem;
              height: .5rem;
              background-color: rgba(255, 255, 255, 0.12);
              border-radius: 2rem;
              margin: auto 1rem;

              &-l::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }

              &-r::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #ffffff;
              }
            }
          }

          &-hide {
            .txt {
              color: #62CDAC;
            }

            .btn-tmblr-r::before {
              background-color:  #62CDAC;
            }
          }
        }
      }

      .content {
        padding: 0 0 1rem 0;
        padding: 0 0 1.5vh 0;
        display: flex;

        &.blur {
          filter: blur(7px);
        }

        .card {
          &-big {
            width: 100%;
            height: 11.5rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background-image: url('/img/two_line.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 1.25rem 1.75rem;


            svg.img {
              width: 3.5rem;
              height: 4rem;
              margin-right: 1rem;
            }

            .data {
              flex-grow: 1;

              .ttl {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                padding-bottom: .25rem;
              }

              .desc {
                font-size: 1.25rem;
                font-weight: 600;
              }
            }
          }
        }

        .col {
          &-1 {
            width: 27rem;
            width: 21vw;
            padding-right: .5rem;
          }

          &-2 {
            flex-grow: 1;
            padding-left: .5rem;
            padding-right: .5rem;
            display: flex;

            .subcol {
              padding: 0 .5rem;

              &-1 {
                width: 30%;
              }

              &-2,
              &-3 {
                width: 35%;
              }
            }
          }
        }
      }
    }

    &_b-info {
      flex-grow: 1;
      position: relative;
      // background-color: #272E35;
      margin-right: 2rem;

      .customer-detail-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .customer-status-info {
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        background-color: var(--c_dark-green);
        border-radius: 1rem;

        .card {
          margin: 1rem 1rem;
          background: var(--gr_green);
          width: auto !important;
        }
      }

      .header {
        padding-right: 4.25rem;

        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .content {
        .row {
          display: flex;
          justify-content: space-between;

          .card {
            border: 1px solid #62CDAC;
            width: 30%;

            &-min {
              width: 20%;
            }
          }
        }
      }
    }

    &_comp-logo {
      width: 23rem;
      position: relative;
      background-color: #272E35;
      overflow: hidden;
      padding-bottom: 1rem;

      .dots {
        position: absolute;

        &-top {
          top: -5px;
          right: 7px;
          width: 2rem;
          opacity: .5;
        }

        &-left {
          top: 6.5rem;
          left: 28px;
          width: 1.5rem;
          opacity: .5;
        }

        &-bottom {
          bottom: -12px;
          right: 1.75rem;
          width: 2.5rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .header {
        .title {
          &::before {
            background-color: rgb(255 255 255 / 25%);
          }
        }
      }

      .logo {
        flex-grow: 1;
        width: 14rem;
        height: 14rem;
        margin: .25rem auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        position: relative;
        z-index: 1;

        .btn {
          width: 3.25rem;
          height: 3.25rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &_update {
            background-color: #62CDAC;

            svg {
              width: 1.75rem;
              height: 1.75rem;
            }
          }

          &_remove {
            background-color: #B03636;

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }
}

.scale {
  position: relative;
  width: 100%;
  height: 1rem;
  margin: 1rem 0 3.5rem 0;
  border-radius: 3rem;
  background-color: rgba(255, 255, 255, 0.12);

  .progress {
    position: relative;
    height: 100%;
    border-radius: 3rem;
    background-color: #ffffff;
    background-image: url('/img/rate_el.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto 100%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5rem;
      background-color: #E2F4EC;
      background-image: url('/img/smile.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 65%;
    }

    .note {
      position: absolute;
      bottom: -3.25rem;
      right: 0;
      transform: translateX(50%);
      background-color: #094F4D;
      color: #ffffff;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 7px 1rem 6px;
      border-radius: 6px;

      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: .75rem;
        height: .75rem;
        background-color: #094F4D;
        border-radius: 2px;
      }
    }
  }
}

.btn {
  &-edit {
    width: 2.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
  &-save {
    width: 3.75rem;
    height: 2.75rem;
    position: absolute;
    top: .75rem;
    right: .75rem;
    background: #22252B;
    border-radius: 6px;
    padding: .75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.form-control {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #1C2126;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: auto 0rem;
  border: 1px solid #2F363D;
  border-radius: .75rem;
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.02);
  outline: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #B9B9B9;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.transparent-box {
  width: 100%;
  border-radius: 14px;
  margin: 1rem 0;
}

.clickable {
  cursor: pointer;
}

.rating-color {
    list-style: none;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    border-radius: 2rem;
    padding-top: 3px;
}

.settings-card {
  padding: 1rem 0.25rem 1rem 1.25rem !important;
  cursor: pointer;
}

.rating-input {
  margin-bottom: 10px;
}

.tmblr {
  display: flex;
  align-items: center;
  padding: 0 .25rem;

  .txt {
    color: #ffffff;
  }

  .btn {
    &-tmblr {
      position: relative;
      width: 1.5rem;
      height: .5rem;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 2rem;
      margin: auto 1rem;

      &-l::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }

      &-r::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }
  }

  &-hide {
    .txt {
      color: rgba(255, 255, 255, 0.4);
    }

    .btn-tmblr-r::before {
      background-color:  #62CDAC;
    }
  }
}

.kyb-rate-comment {
  margin-top: 10px;
}

.notifications_tbl {
  width: 100%;
  margin-top: 20px;
}

.risk-form-control {
  margin-bottom: 0px;
  margin-left: 10px;
}

.manual-risk-select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.calculated-risk {
  margin-bottom: 10px;
}

.risk-edit-block {
  display: flex;
  flex-direction: column;
}
</style>
