import './assets/css/main.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cookies from 'js-cookie'
import Echo from 'laravel-echo'

window.io = require('socket.io-client')

require('./plugins');

window.axios = require('axios')
window.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL

import { mixins } from './mixins';
Vue.mixin(mixins);

Vue.config.productionTip = false

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.state.token) {
      const token = cookies.get('x-access-token')
      if (token) {
  	    store.dispatch('setTokenFromCookie', { token })
  	  } else {
  	    return next({name:'Login'});
  	  }
    }
    next();
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
