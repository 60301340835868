<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">Create Legal Provider</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Email</div>
        <div class="data">
          <input type="text" name="email" class="inpt" v-model="form.email">
          <span v-show="formErrors.email" class="invalid-feedback">{{ formErrors.email }}</span>
        </div>
      </div>
      <div class="row">
        <div class="label">Password</div>
        <div class="data">
          <input type="text" name="password" class="inpt" v-model="form.password">
          <span v-show="formErrors.password" class="invalid-feedback">{{ formErrors.password }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    countries: [],
    form: {
      email: null,
      password: null,
      reg_admin: true
    },
    formErrors: {
      email: null,
      password: null,
    },
  }),
  methods: {
    ...mapActions({
      getLegalProviders: 'legal_providers/getLegalProviders'
    }),
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    resetErrors() {
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.formErrors, key)) {
          this.formErrors[key] = null
        }
      }
    },
    async save () {
      this.resetErrors()
      if (!this.form.email) {
        this.formErrors.email = 'Please, enter email'
        return
      }
      if (!this.validateEmail(this.form.email)) {
        this.formErrors.email = 'Please, enter valid email'
        return
      }
      if (!this.form.password) {
        this.formErrors.password = 'Please, enter password'
        return
      }
      if (this.form.password.length < 6) {
        this.formErrors.password = 'Please, password must have at least 6 symbols'
        return
      }

      await axios.post('admin/legal-provider/create', this.form)
        .then(res => {
          this.close()
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(this.form, key)) {
              this.form[key] = null
            }
          }
          this.getLegalProviders()
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(this.form, key)) {
              this.form[key] = null
            }
          }
        })
        .catch(err => { 
          if (err.response.status == 422) {
            const errors = err.response.data.errors
            if (errors.email) {
              this.formErrors.email = errors.email[0]
            } else if (errors.password) {
              this.formErrors.email = errors.password[0]
            }
            console.log(err.response.data.errors)
          } else {
            console.log(err)
          }
        });  
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 11rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
</style>
