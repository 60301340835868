import Vue from 'vue'
import Vuex from 'vuex'
import cookies from 'js-cookie'
import notificationsModule from './modules/notifications'
import bosBusinessModule from './modules/bos_business'
import tariffPackagesModule from './modules/tariff_packages'
import softwareNetModule from './modules/software_net'
import legalProvidersModule from './modules/legal_providers'
import paymentAtlasModule from './modules/payment_atlas'
import administratorsModule from './modules/administrators'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	token: null,
    user: null,
    content: {},
    modal: {
      template: null,
      options: {}
    },
    deleteItemFromTableData: {
      id: null,
      table: '',
      actionType: '',
    },
    editItemFromTableData: {
      id: null,
      table: '',
      actionType: '',
    },
    messagesCount: 0,
    newMessage: null,
    newChatRoom: null,
    chatRoomApplyOfferObj: {},
    chatOfferUser: {},
    chatOffer: {}
  },
  mutations: {
  	SET_TOKEN (state, token) {
	    state.token = token
	  },

	  REMOVE_TOKEN (state) {
	    state.token = null
	  },

    SET_USER (state, user) {
      state.user = user
    },

    SET_CONTENT (state, content) {
      state.content = content
    },

    REMOVE_USER (state) {
      state.user = null
    },

    REMOVE_CONTENT (state) {
      state.content = {}
    },

    setModal(state, payload) {
      Object.assign(state.modal, payload)
    },
    closeModal(state) {
      Object.assign(state.modal, {template: null, options: {}})
    },

    DELETE_ITEM_FROM_TABLE(state, payload) {
      state.deleteItemFromTableData = payload
    },

    EDIT_ITEM_FROM_TABLE(state, payload) {
      state.editItemFromTableData = payload
    },

    SET_MESSAGES_COUNT (state, messagesCount) {
      state.messagesCount = messagesCount
    },

    CREATE_NEW_CHAT_MESSAGE(state, newMessage) {
      state.newMessage = newMessage
    },

    CREATE_CHAT_ROOM(state, newChatRoom) {
      state.newChatRoom = newChatRoom
    },

    DECREMENT_MESSAGE_COUNT(state, count) {
      console.log('DECREMENT_MESSAGE_COUNT')
      if (!count) {
        state.messagesCount = state.messagesCount - 1
      } else {
        state.messagesCount = state.messagesCount - count
      }
    },

    CHANGE_CHAT_ROOM_APPLY_OFFER(state, chatRoomApplyOfferObj) {
      state.chatRoomApplyOfferObj = chatRoomApplyOfferObj
    },

    SET_CHAT_OFFER_USER(state, chatOfferUser) {
      state.chatOfferUser = chatOfferUser
    },

    SET_CHAT_OFFER (state, chatOffer) {
      state.chatOffer = chatOffer
    }

  },
  getters: {
    contentArr: state => {
      return (state.content) ? state.content : {}
    },

    deleteItemFromTable: state => state.deleteItemFromTable(),
    getNewMessage: state => state.newMessage,
    getNewChatRoom: state => state.newChatRoom,
    getChatRoomApplyOfferObj: state => state.chatRoomApplyOfferObj,
    getChatOfferUser: state => state.chatOfferUser,
    getChatOffer: state => state.chatOffer,
  },
  actions: {
  	setToken ({ commit }, { token, expiresIn }) {
  	  axios.defaults.headers.common.Authorization = 'Bearer ' + token
      const expiryTime = new Date(new Date().getTime() + expiresIn * 1000)
      cookies.set('x-access-token', token, { expires: expiryTime })
	    commit('SET_TOKEN', token)
	  },

    async setTokenFromCookie ({ commit, dispatch }, { token }) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
      commit('SET_TOKEN', token)
      const userData = await axios.get('auth/me')
        .catch((errors) => {
          console.dir(errors)
          dispatch('logout')
        })
      commit('SET_USER', userData.data)
      const contentData = await axios.get('content')
      .catch((errors) => {
        console.dir(errors)
        dispatch('logout')
      })
      commit('SET_CONTENT', contentData.data)
    },

    async setUser ({ commit, dispatch }) {
      const userData = await axios.get('auth/me')
        .catch((errors) => {
          console.dir(errors)
          dispatch('logout')
        })
      commit('SET_USER', userData.data)
    },

    async setContent ({ commit, dispatch }) {
      const contentData = await axios.get('content')
        .catch((errors) => {
          console.dir(errors)
          dispatch('logout')
        })
      commit('SET_CONTENT', contentData.data)
    },

	  logout ({ commit }) {
      axios.defaults.headers.common.Authorization = ''
      cookies.remove('x-access-token')
      commit('REMOVE_TOKEN')
      commit('REMOVE_USER')
      commit('REMOVE_CONTENT')
    },

    deleteItemFromTable: (context, payload) => {
      context.commit('DELETE_ITEM_FROM_TABLE', payload)
    },

    editItemFromTable: (context, payload) => {
      context.commit('EDIT_ITEM_FROM_TABLE', payload)
    },

    async setCountMessagesRead({ commit, dispatch }) {
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'chat/count-messages-read')
      .then(({ data }) => {
        if(data.success) {
          commit('SET_MESSAGES_COUNT', 0)
        }
      })
      .catch((error) => console.log(error))
    },
  },
  modules: {
    notifications: notificationsModule,
    bos_business: bosBusinessModule,
    tariff_packages: tariffPackagesModule,
    software_net: softwareNetModule,
    legal_providers: legalProvidersModule,
    payment_atlas: paymentAtlasModule,
    administrators: administratorsModule,
  }
})
