<template>
  <div class="box box_compliance">
    <div class="header">
      <div class="title">Compliance</div>
      <div class="block">
        <Stat class="img" />
        <div class="block_content">
          <div class="subtxt">Status</div>
          <div class="txt">{{ paid }}</div>
        </div>
      </div>
      <div class="block">
        <Graph class="img" />
        <div class="block_content">
          <div class="subtxt">
            Pre-chack type of risk
            <Info pos="tr" txt="Compliance risk status, getting type including a provided date by client and isn’t a final . Can be change after full Compliance review" />
          </div>
          <div class="txt">{{ risk }} <ArrowFlat class="high" /></div>
        </div>
      </div>
      <div class="block">
        <CircleLoad class="img" />
        <div class="block_content">
          <div class="subtxt">Additional compliance request</div>
          <div class="txt">{{ $store.getters.contentArr.additional_compliance_request }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from './Info.vue'
import Stat from './../img/Stat.vue'
import Graph from './../img/Graph.vue'
import CircleLoad from './../img/CircleLoad.vue'
import ArrowFlat from './../img/ArrowFlat.vue'

export default {
  components: {
    Info,
    Stat,
    Graph,
    CircleLoad,
    ArrowFlat,
  },
  data() {
    return {
        risk: '',
        paid: '',
        additionalComplianceRequest: ''
    }
  },
  watch: {
    '$store.state.user': function() {
      this.risk = (this.$store.state.user && this.$store.state.user.risk) ? this.$store.state.user.risk : '';
      this.paid = (this.$store.state.user && this.$store.state.user.is_paid == 1) ? 'Paid' : 'Unpaid';
      this.additionalComplianceRequest = (this.$store.state.user) ? this.$store.state.user.additional_compliance_request : '';
    }
  }
}
</script>

<style lang="scss" scoped>
.box_compliance {
  background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);

  .header {
    display: flex;
    align-items: center;
    background-image: url('/img/group_61.png');
    background-position: 102% -0.5rem;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 14px;

    .block {
      display: flex;
      padding: .25rem 1rem;

      svg.img {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 10px;
      }

      &_content {
        padding: 0 .75rem;

        .subtxt {
          position: relative;
          font-size: 10px;
        }

        .txt {
          display: flex;
          align-items: center;

          ::v-deep svg {
            width: .75rem;
            height: .75rem;
            margin: 6px;

            &.high {
              transform: rotate(-90deg);
              path {
                fill: #B03636;
              }
            }
          }
        }
      }
    }
  }
}
</style>